import React from 'react'

const WIDTH = 716
const HEIGHT = 449

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Zurich = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="716"
    height="449"
    viewBox="0 0 716 449"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="784za">
        <path
          fill="#fff"
          d="M280.302 7.027C463.942-22.393 657.995 42.7 705.667 164.52c54.498 139.263-113.62 278.506-344.086 284.3-202.19 5.085-357.92-94.204-360.552-214.25C-1.301 128.263 115.404 33.445 280.302 7.027z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M280.302 7.027C463.942-22.393 657.995 42.7 705.667 164.52c54.498 139.263-113.62 278.506-344.086 284.3-202.19 5.085-357.92-94.204-360.552-214.25C-1.301 128.263 115.404 33.445 280.302 7.027z"
          clipPath='url("#784za")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Zurich
