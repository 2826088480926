import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from '.'
import { up, addResponsivity } from '../lib/styles'

// default values belong to Page title
const Title = styled.span.attrs((props) => ({
  as: props.as || 'h2',
}))`
  ${({
    theme: { colors, fonts },
    fontSize = '48px',
    fontWeight = 700,
    lineHeight,
    mobileFontSize,
    mobileLineHeight,
    mobileFontWeight,

    white,
    gray,
    black,
    green,
    textColor,

    maxWidth,
    textAlign,
    alignSelf,
    mobileTextAlign,
    display = 'block',
    bp = 'mobile', // can be px or bp name
    margin,
  }) => css`
    font-weight: ${mobileFontWeight || fontWeight};

    color: ${colors.textWhite};
    color: ${white && colors.textWhite};
    color: ${gray && colors.textGray};
    color: ${black && colors.textBlack};
    color: ${green && colors.textGreen};

    ${textColor
    && css`
      color: ${textColor};
    `}

    font-family: ${fonts.hind};

    max-width: ${maxWidth};
    align-self: ${alignSelf};
    display: ${display};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};

    /* reset */
    margin: 0;
    margin: ${margin};
    padding: 0;

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      text-align: ${textAlign};
      font-weight: ${fontWeight};
    }

    ${addResponsivity({ displayAs: display })}
  `}
`

Title.Large = (props) => (
  <Title
    fontSize="48px"
    // lineHeight="48px"
    fontWeight="700"
    mobileFontSize="30px"
    // mobileLineHeight="30px"
    white
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.Big = (props) => (
  <Title
    fontSize="42px"
    // lineHeight="42px"
    fontWeight="700"
    mobileFontSize="30px"
    // mobileLineHeight="30px"
    black
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.Medium = (props) => (
  <Title
    fontSize="36px"
    // lineHeight="36px"
    mobileFontSize="26px"
    // mobileLineHeight="26px"
    fontWeight="700"
    white
    as={props.innerAs || 'h3'}
    {...props}
  />
)

Title.Small = (props) => (
  <Title
    fontSize="24px"
    // lineHeight="24px"
    mobileFontSize="24px"
    // mobileLineHeight="24px"
    fontWeight="700"
    white
    as={props.innerAs || 'h4'}
    {...props}
  />
)

Title.Story = (props) => (
  <Title
    fontSize="110px"
    // lineHeight="110px"
    mobileFontSize="60px"
    // mobileLineHeight="60px"
    fontWeight="300"
    black
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.Thin = (props) => (
  <Title
    fontSize="36px"
    mobileFontSize="26px"
    fontWeight="400"
    mobileFontWeight="700"
    black
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.StorySub = (props) => (
  <Title
    fontSize="36px"
    // lineHeight="36px"
    mobileFontSize="26px"
    // mobileLineHeight="26px"

    fontWeight="400"
    mobileFontWeight="700"
    black
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.Job = (props) => (
  <Title
    fontSize="20px"
    // lineHeight="36px"
    mobileFontSize="20px"
    // mobileLineHeight="26px"

    fontWeight="700"
    mobileFontWeight="700"
    black
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.Usage = (props) => (
  <Title
    fontSize="30px"
    // lineHeight="36px"
    mobileFontSize="20px"
    // mobileLineHeight="26px"

    fontWeight="700"
    mobileFontWeight="700"
    white
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.UsagePlaneType = (props) => (
  <Title
    fontSize="36px"
    // lineHeight="36px"
    mobileFontSize="26px"
    bp="tablet"
    // mobileLineHeight="26px"

    fontWeight="400"
    mobileFontWeight="700"
    white
    textAlign="center"
    as={props.innerAs || 'h3'}
    {...props}
  />
)

Title.UsageBox = styled(Title.Small).attrs(() => ({
  mobileFontSize: '18px',
}))`
  ${({ theme: { colors }, isZuri }) => css`
    color: ${isZuri ? colors.usageYellow : colors.usageBlue};
  `}
`

export default Title
