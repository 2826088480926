import React from 'react'

const LandingAreaIcon = () => (
  <svg
    width="600"
    height="600"
    viewBox="0 0 600 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M420.964 88.0798C412.193 96.8506 404.965 104.484 404.965 104.89C404.965 105.297 412.518 113.255 421.776 122.513L438.668 139.405H288.996H139.405V288.996V438.668L122.513 421.776C113.255 412.518 105.297 404.965 104.89 404.965C104.403 404.965 96.8506 412.193 87.9986 421.045L72 437.044L116.991 482.522L161.982 528L207.298 482.765L252.613 437.531L236.858 421.451C228.169 412.599 220.697 405.209 220.291 405.046C219.966 404.884 212.008 412.355 202.669 421.695L185.695 438.668V312.141V185.695H312.141H438.668L421.776 202.587C412.518 211.845 404.965 219.804 404.965 220.21C404.965 221.266 435.988 252.288 437.044 252.288C437.45 252.288 457.996 232.148 482.684 207.46L527.513 162.631L482.765 117.316C458.158 92.384 437.856 72 437.531 72C437.287 72 429.816 79.2278 420.964 88.0798Z"
      fill="black"
    />
    <path
      d="M479.679 276.246V299.391H502.825H525.97V276.246V253.1H502.825H479.679V276.246Z"
      fill="black"
    />
    <path
      d="M479.679 367.202V390.347H502.825H525.97V367.202V344.057H502.825H479.679V367.202Z"
      fill="black"
    />
    <path
      d="M479.679 457.752V480.491H456.94H434.201V503.637V526.782H480.085H525.97V480.898V435.013H502.824H479.679V457.752Z"
      fill="black"
    />
    <path
      d="M253.101 503.637V526.782H276.246H299.391V503.637V480.491H276.246H253.101V503.637Z"
      fill="black"
    />
    <path
      d="M344.057 503.637V526.782H367.202H390.347V503.637V480.491H367.202H344.057V503.637Z"
      fill="black"
    />
  </svg>
)

export default LandingAreaIcon
