import React from 'react'

const Unmute = ({ color = 'black' }) => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 0.910152C39.8629 0.910152 51.1429 12.1657 51.1429 26C51.1429 39.8343 39.8629 51.0898 26 51.0898C12.1371 51.0898 0.857143 39.8343 0.857143 26C0.857143 12.1657 12.1371 0.910152 26 0.910152ZM26 0C11.64 0 0 11.6408 0 26C0 40.3592 11.64 52 26 52C40.36 52 52 40.3592 52 26C52 11.6408 40.36 0 26 0Z"
      fill={color}
    />
    <path d="M11.1428 37.3162H10.2856V38.6814H11.1428V37.3162Z" fill={color} />
    <path d="M17.1428 37.3162H16.2856V38.6814H17.1428V37.3162Z" fill={color} />
    <path d="M23.4287 37.3162H22.5715V38.6814H23.4287V37.3162Z" fill={color} />
    <path d="M29.7143 37.3162H28.8572V38.6814H29.7143V37.3162Z" fill={color} />
    <path d="M36 37.3162H35.1428V38.378H36V37.3162Z" fill={color} />
    <path d="M42 37.3162H41.1428V38.6814H42V37.3162Z" fill={color} />
  </svg>
)

export default Unmute
