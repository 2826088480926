import React from 'react'
import styled, { css } from 'styled-components'

import { Pointer } from './images'
import Title from '../Title'
import { up, addResponsivity } from '../../lib/styles'

const Wrapper = styled.div`
  ${({ top, left }) => css`
    position: absolute;
    width: 1000px;
    top: ${top}px;
    left: ${left}px;

    ${up('mobile')} {
      top: ${top}px;
    }
    ${addResponsivity()}
  `}
`

const CityDescription = ({ children, textStyle, ...props }) => (
  <Wrapper {...props}>
    <Title.Medium fontSize="36px" mobileFontSize="36px" style={{ position: 'relative', ...textStyle }}>
      {children}
    </Title.Medium>
    <Pointer />
  </Wrapper>
)

export default CityDescription
