import React, { Fragment } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Row, Col, Gap, Text, Title, Link, YouTube } from '.'
import { up } from '../lib/styles'

import twitter from '../data/images/social/twitter.svg'
import facebook from '../data/images/social/facebook.svg'
import instagram from '../data/images/social/instagram.svg'
import linkedin from '../data/images/social/linkedin.svg'
import youtube from '../data/images/social/youtube.svg'

const POST_BP = 'tablet'

const formatDate = (dateToFormat) => {
  let format = 'MMMM Do'
  const momentDate = moment(dateToFormat)
  const currentYear = moment().format('YYYY')
  if (currentYear !== momentDate.format('YYYY')) format += ' YYYY'

  return momentDate.format(format)
}

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const getProcessingInstructions = (images) => [
  // youtube video
  {
    shouldProcessNode(node) {
      const { type, data, name, children } = node
      if (type === 'tag' && name === 'p' && children && children[0] && children[0].data) {
        return children[0].data.startsWith('[[video ')
      }
      return false
    },
    processNode(node, children) {
      const videoCode = children[0].split('[[video ')[1].split(']]')[0]
      return (
        <Fragment key={videoCode}>
          <Gap gap="12px" />
          <YouTube videoId={videoCode} />
          <Gap gap="30px" />
        </Fragment>
      )
    },
  },
  {
    // Custom <img> processing
    shouldProcessNode(node) {
      return node.name === 'img'
    },
    processNode(node, children, index) {
      const foundImage = images.find(
        (image) => node.attribs.src && node.attribs.src.indexOf(image.relativePath) !== -1,
      )
      if (foundImage && foundImage.contentImage) {
        return (
          <Col maxWidth="100%" key={node.attribs.src + index}>
            <Gap gap="8px" mobileGap="8px" />
            <Img alt={node.attribs.alt} fluid={foundImage.contentImage.fluid} />
            <Gap gap="8px" mobileGap="8px" />
          </Col>
        )
      }
      return <img {...node.attribs} />
    },
  },
  {
    // Anything else
    shouldProcessNode(node) {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]
const htmlToReactParser = new HtmlToReactParser()

const PostWrapper = (props) => <Col width="100%" maxWidth="814px" background="white" {...props} />
const PostContentWrapper = (props) => (
  <Col padding="40px 48px 22px 48px" mobilePadding="28px 16px 8px 16px" width="100%" {...props} />
)

const PostTitle = styled(Title.Small)`
  display: block;
  color: black;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
  padding: 0;
  ${up('mobile')} {
    font-size: 24px;
    margin: 0 0 24px 0;
    padding: 0;
  }
`

const Content = styled.div`
  font-size: 24px;
  color: black;

  /* img {
    width: 100%;
    height: auto;
    margin: 18px 0;
  } */

  li,
  p {
    color: #8d8d8f;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    ${up(POST_BP)} {
      font-size: 24px;
      line-height: 33px;
    }
  }
  p {
    margin: 0 0 16px 0;
  }

  ol,
  ul {
    margin: 0 0 16px 0;
  }

  strong {
    color: black;
  }

  h2,
  h3 {
    display: block;
    color: black;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;
    margin: 0 0 16px 0;
    padding: 0;
    ${up('mobile')} {
      font-size: 24px;
      margin: 0 0 16px 0;
      padding: 0;
    }
  }
`

const PostInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 814px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;

  ${up(400)} {
    flex-direction: row;
  }

  ${up(POST_BP)} {
    width: 245px;
    justify-content: flex-start;
    flex-direction: column;
  }
`

const SocialLink = styled.a.attrs(() => ({ target: '_blank' }))`
  padding: 10px;
  img {
    width: auto;
    height: 18px;
    ${up('mobile')} {
      height: 24px;
    }
  }
`

const Socials = ({
  post: { twitterLink, facebookLink, instagramLink, linkedinLink, youtubeLink },
}) => {
  const data = []
  if (twitterLink) data.push({ link: twitterLink, icon: twitter, type: 'twitter' })
  if (facebookLink) data.push({ link: facebookLink, icon: facebook, type: 'facebook' })
  if (instagramLink) data.push({ link: instagramLink, icon: instagram, type: 'instagram' })
  if (linkedinLink) data.push({ link: linkedinLink, icon: linkedin, type: 'linkedin' })
  if (youtubeLink) data.push({ link: youtubeLink, icon: youtube, type: 'youtube' })

  if (data.length === 0) return null

  return (
    <Col>
      <Gap gap="48px" showAfter={POST_BP} />
      <Text fontSize="16px" mobileFontSize="16px">
        Also published on:
      </Text>
      <Row margin="0 0 0 -10px">
        {data.map(({ link, type, icon }, key) => (
          <Fragment key={key}>
            <SocialLink href={link}>
              <img src={icon} alt={`${type} logo`} />
            </SocialLink>
            <Gap gap="8px" mobileGap="4px" bp="menu" />
          </Fragment>
        ))}
      </Row>
    </Col>
  )
}

const ArticleLink = ({ to, innerAs, ...props }) => (
  <Link to={to}>
    <Title as={innerAs} black fontSize="24px" mobileFontSize="20px" {...props} />
  </Link>
)

const MorePosts = ({ posts }) => {
  if (!posts || posts.length === 0) return null
  return (
    <Row width="100%" responsive justifyContent="center" bp={POST_BP}>
      <PostWrapper>
        <PostContentWrapper>
          <Title as="h3" gray fontSize="18px">
            More articles
          </Title>
          <Gap gap="28px" />
          {posts.map(({ slug, title }, key) => (
            <Fragment key={slug}>
              <ArticleLink
                to={`${Link.NEWS}/${slug}`}
                innerAs="h4"
                style={{ textTransform: 'uppercase' }}
              >
                {title}
              </ArticleLink>
              {key + 1 !== posts.length && <Gap gap="24px" />}
            </Fragment>
          ))}
        </PostContentWrapper>
        <Gap gap="20px" />
      </PostWrapper>
      {/* for width align */}
      <Gap gap="48px" />
      <PostInfo />
    </Row>
  )
}

const Post = ({ post, isSinglePost = false, contentImages }) => {
  const content = isSinglePost && post.contentExtended ? `${post.content}${post.contentExtended}` : post.content
  const parsedContent = htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    getProcessingInstructions(contentImages),
  )
  return (
    <>
      <Row width="100%" justifyContent="center" responsive bp={POST_BP}>
        <PostWrapper>
          {post.titleImage?.filename?.image?.fluid && (
            <Img fluid={post.titleImage.filename.image.fluid} />
          )}
          <PostContentWrapper>
            {isSinglePost ? (
              <PostTitle as="h1">{post.title}</PostTitle>
            ) : (
              <Link to={`${Link.NEWS}/${post.slug}`}>
                <PostTitle as="h2">{post.title}</PostTitle>
              </Link>
            )}
            <Content>{parsedContent}</Content>
            {!isSinglePost && post.contentExtended && (
              <>
                <Gap gap="10px" />
                <ArticleLink to={`${Link.NEWS}/${post.slug}`} innerAs="div">
                  Read more...
                </ArticleLink>
                <Gap gap="16px" />
              </>
            )}
          </PostContentWrapper>
        </PostWrapper>
        <Gap gap="48px" mobileGap="16px" bp={POST_BP} />
        <PostInfo>
          <Col>
            <Text mobileFontSize="16px">{post.author}</Text>
            <Text mobileFontSize="16px">{formatDate(post.createdAt)}</Text>
          </Col>
          <Gap gap="8px" showBelow={POST_BP} />
          <Socials post={post} />
        </PostInfo>
      </Row>
      <Gap gap="32px" mobileGap="56px" bp={POST_BP} />
    </>
  )
}

Post.MorePosts = MorePosts

export default Post
