import React from 'react'

const WIDTH = 610
const HEIGHT = 412

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Prague = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="610"
    height="412"
    viewBox="0 0 610 412"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="niuta">
        <path
          fill="#fff"
          d="M262.682.023C416.047-2.93 564.79 74.172 601.098 181.82c43.287 128.341-93.893 240.48-287.767 229.434C133.269 400.997-6.757 289.557 1.306 173.537 8.362 71.999 123.469 2.702 262.682.023z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M262.682.023C416.047-2.93 564.79 74.172 601.098 181.82c43.287 128.341-93.893 240.48-287.767 229.434C133.269 400.997-6.757 289.557 1.306 173.537 8.362 71.999 123.469 2.702 262.682.023z"
          clipPath='url("#niuta")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Prague
