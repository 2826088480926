import React from 'react'
import styled, { css } from 'styled-components'
import topVideoSrc from '../data/videos/technology-top.mp4'
import Video from './Video'
import Section from './Section'
import { up } from '../lib/styles'

const Wrapper = styled(Section.FullHeight)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  /* height: auto; */
  overflow: hidden;

  video {
    width: 160%;
    ${up('mobile')} {
      width: 100%;
    }
  }
`

const TechnologyTopVideo = () => (
  <Wrapper>
    <Video src={topVideoSrc} centered muted autoPlay loop />
  </Wrapper>
)

export default TechnologyTopVideo
