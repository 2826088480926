import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class SelectFileField extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onClick = () => {
    this.inputRef.current.value = null
    this.inputRef.current.click()
  }

  render() {
    const { onSelect, ...props } = this.props
    return (
      <>
        <input
          ref={this.inputRef}
          type="file"
          name="file"
          style={{ display: 'none' }}
          onChange={onSelect}
        />
        <div
          {...props}
          onClick={this.onClick}
          style={{ cursor: 'pointer', display: 'inline-block', alignSelf: 'flex-start' }}
        />
      </>
    )
  }
}

SelectFileField.defaultProps = {}

SelectFileField.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default SelectFileField
