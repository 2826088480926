import React from 'react'

const HybridIcon = () => (
  <svg
    width="600"
    height="600"
    viewBox="0 0 600 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M272.622 76.0491C231.733 81.2201 193.047 97.4032 161.255 122.683C121.42 154.188 92.6923 200.344 81.1055 251.287L79.7649 257.032L90.8729 251.478C98.342 247.84 105.332 245.158 112.035 243.531L122.186 240.945L124.388 234.625C127.644 225.145 137.028 206.472 143.348 196.705C151.392 184.543 159.149 175.159 170.544 164.051C205.591 130.057 250.215 111.959 299.052 111.959C342.622 111.959 381.787 125.843 416.164 153.518L423.921 159.742L430.719 157.444C440.391 154.188 450.541 152.273 459.447 151.986C466.15 151.794 467.012 151.602 466.342 150.262C464.618 147.102 442.498 126.514 433.784 120.002C403.333 97.2117 369.721 82.9437 332.471 76.9109C319.544 74.8042 285.933 74.3255 272.622 76.0491Z"
      fill="black"
    />
    <path
      d="M447.477 165.009C405.918 172.957 375.658 207.334 372.881 249.755C369.913 294.283 399.023 334.118 442.402 345.226C452.935 347.907 476.3 347.716 486.642 344.843C518.243 336.129 542.469 311.998 551.758 279.919C553.673 273.312 553.96 270.247 553.96 255.596C553.96 240.945 553.673 237.881 551.758 231.274C542.469 199.195 517.764 174.585 486.642 166.445C477.066 163.86 457.149 163.189 447.477 165.009ZM489.707 232.04C510.103 262.682 513.55 270.918 511.444 284.324C507.901 307.21 488.653 322.531 463.373 322.627C456.67 322.627 445.179 319.754 438.476 316.307C432.443 313.243 423.346 304.433 420.473 298.879C417.983 293.9 415.59 284.228 415.59 278.483C415.59 268.141 419.037 260.959 437.71 232.519C445.466 220.644 454.563 206.089 457.819 200.248L463.756 189.523L470.268 201.014C473.811 207.334 482.62 221.315 489.707 232.04Z"
      fill="black"
    />
    <path
      d="M486.355 268.524C484.727 272.258 484.44 274.556 484.727 281.93C485.014 291.984 486.929 296.006 491.526 296.581C493.633 296.868 494.399 296.198 496.122 292.75C497.846 289.495 498.325 287.005 498.325 280.493C498.325 270.056 496.122 264.885 491.334 264.406C488.557 264.119 488.174 264.406 486.355 268.524Z"
      fill="black"
    />
    <path
      d="M124.963 252.723C100.544 256.171 78.8073 268.811 64.4435 287.962C55.8253 299.358 50.367 311.615 47.3028 325.787C45.7706 333.448 45.4834 352.312 47.0155 360.547C50.1755 378.646 59.7514 396.936 72.966 409.959C95.8523 432.462 128.697 441.272 160.393 433.132C167.192 431.409 181.939 424.61 188.163 420.301C205.304 408.522 219.189 388.509 224.839 367.729C228.19 354.993 228.382 334.98 225.222 322.244C222.924 312.955 216.604 298.687 211.624 291.506C199.654 274.077 179.832 260.288 159.436 255.117C149.19 252.532 133.868 251.479 124.963 252.723ZM144.115 303.284C140.667 320.137 137.89 334.405 137.89 334.98C137.89 335.65 144.881 336.129 158.095 336.225L178.205 336.512L146.892 376.06C129.751 397.797 115.483 415.513 115.291 415.321C115.195 415.13 119.696 400.574 125.537 382.955C131.283 365.335 135.975 350.588 135.975 350.11C135.975 349.727 126.686 349.439 115.483 349.439H94.8947L122.282 310.657C137.316 289.303 149.86 271.971 150.052 272.162C150.243 272.354 147.562 286.335 144.115 303.284Z"
      fill="black"
    />
    <path
      d="M510.869 347.716C503.113 351.833 492.675 355.568 483.195 357.483C479.652 358.249 476.588 359.015 476.492 359.207C476.3 359.398 475.247 362.271 474.098 365.718C470.842 375.007 461.458 393.871 455.808 402.585C439.625 427.291 417.505 448.549 392.895 463.009C372.402 475.074 349.133 483.214 324.523 486.757C311.309 488.768 287.177 488.768 273.963 486.853C239.873 481.873 206.549 467.126 181.077 445.868L174.853 440.697L167.863 442.995C158.478 445.964 145.838 448.071 137.794 448.071C134.156 448.071 131.187 448.454 131.187 448.932C131.187 450.56 155.893 473.734 164.703 480.245C198.505 505.526 237.096 520.655 278.176 524.773C293.306 526.305 319.64 525.348 334.674 522.858C383.223 514.623 425.357 492.502 459.16 457.551C482.429 433.515 500.336 404.022 510.678 372.613C515.082 359.59 518.53 346.279 517.859 344.843C517.668 344.46 514.604 345.801 510.869 347.716Z"
      fill="black"
    />
  </svg>
)

export default HybridIcon
