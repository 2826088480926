import React from 'react'

const WIDTH = 589
const HEIGHT = 368

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const London = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="589"
    height="368"
    viewBox="0 0 589 368"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="h3kza">
        <path
          fill="#fff"
          d="M184.031 37.02C329.165-22.086 509.358-11.8 570.264 77.344c70.635 103.382-67.56 247.673-268.536 282.99-162.215 28.506-284.99-26.62-299.46-111.633C-10.355 174.538 59.712 87.648 184.031 37.02z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M184.031 37.02C329.165-22.086 509.358-11.8 570.264 77.344c70.635 103.382-67.56 247.673-268.536 282.99-162.215 28.506-284.99-26.62-299.46-111.633C-10.355 174.538 59.712 87.648 184.031 37.02z"
          clipPath='url("#h3kza")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default London
