import React from 'react'

const WIDTH = 750
const HEIGHT = 634

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Roma = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="750"
    height="634"
    viewBox="0 0 750 634"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="g4xha">
        <path
          fill="#fff"
          d="M382.513 1.206c197.155 14.46 367.47 159.207 367.478 337.223.01 203.506-224.876 341.447-459.998 281.401C83.717 567.15-34.365 387.652 10.429 225.284 50.095 81.5 205.479-11.778 382.513 1.206z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M382.513 1.206c197.155 14.46 367.47 159.207 367.478 337.223.01 203.506-224.876 341.447-459.998 281.401C83.717 567.15-34.365 387.652 10.429 225.284 50.095 81.5 205.479-11.778 382.513 1.206z"
          clipPath='url("#g4xha")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Roma
