import React from 'react'
import styled, { css } from 'styled-components'

import { up } from '../lib/styles'
import App from './App'
import { Menu, Footer } from '../components'

const Page = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: black;
  `}
`

const PageContent = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${sizes.maxPageWidth};
    /* align-content: stretch; */
  `}
`
class DefaultLayout extends React.Component {
  render() {
    // these props are exactly same as pageProps injected by gatsby
    // console.log('this.props LAYOUT', this.props)
    const {
      location: { pathname },
      pageContext: { trimmedPath },
      children,
    } = this.props

    return (
      <App trimmedPath={trimmedPath}>
        <Menu pathname={pathname} />
        <Page>
          <PageContent>{children}</PageContent>
        </Page>
        <Footer />

      </App>
    )
  }
}

export default DefaultLayout
