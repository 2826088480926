import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { TweenLite, TimelineMax, Linear, Power1, Power0, Power2 } from 'gsap'
import axios from 'axios'
import Text from './Text'
import Title from './Title'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Button from './Button'
import TextField from './TextField'
import TextAreaField from './TextAreaField'
import SelectFileField from './SelectFileField'
import { up, down, addPagePadding } from '../lib/styles'

import closeIconSrc from '../data/images/career/close-icon.svg'
import zuriCrossing from '../data/images/career/zuri.png'

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const SpamCheck = styled.input`
  display: none;
`

const Content = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    p {
      font-size: 20px;
      font-weight: 400;
      font-family: ${fonts.hind};
      color: ${colors.textWhite};
      margin: 0 0 25px;

      ${up('tablet')} {
        margin: 0 0 35px;
        font-size: 28px;
      }
    }
    h2 {
      color: ${colors.textWhite};
      font-family: ${fonts.hind};
      font-size: 30px;
      font-weight: 700;
      margin: 30px 0 20px;
      ${up('tablet')} {
        margin: 60px 0 40px;
      }
    }
    ul,
    li {
      font-size: 20px;
      font-family: ${fonts.hind};
      color: ${colors.textWhite};
      margin: 0 0 20px;
      ${up('tablet')} {
        margin: 0 0 35px;
        font-size: 28px;
      }
    }
  `}
`

const CloseImage = styled.img`
  position: absolute;
  cursor: pointer;
  top: -20px;
  right: -20px;
  padding: 20px;
  width: 76px;
  ${up('mobile')} {
    width: auto;
  }
`

const Wrapper = styled.section`
  padding-top: 60px;
  background: linear-gradient(#105dbc, #1e8ad1);
  position: relative;
  display: flex;
  justify-content: center;
  ${addPagePadding()}
  ${up('tablet')} {
    padding-top: 150px;
  }
`

const Label = styled(Text).attrs(() => ({ as: 'label' }))`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 20px;
    font-weight: 700;
  `}
`

const AddCvText = styled(Text)`
  ${({ theme: { colors } }) => css`
    font-size: 30px;
    color: ${colors.textWhite};
    font-weight: 700;
    padding: 30px 20px 30px 0;
  `}
`

const ThanksText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 36px;
    font-weight: 700;
  `}
`

const ZuriWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 60px 24px 0;
  top: -100vh;
  left: 0;
  z-index: 100;
  display: none;
  pointer-events: none;
  img {
    max-width: 100%;
    margin: auto;
    display: block;
    position: relative
  }
`

const fieldsDef = ['name', 'email', 'message']
const createFields = ({ fieldsDef, state, setState }) => fieldsDef.reduce((result, key) => {
  result[key] = {
    value: state[key],
    error: state[`${key}Error`],
    onChange: e => {
      setState({ [key]: e.target.value })
    },
    onBlur: () => setState({ [`${key}Error`]: '' }),
  }
  return result
}, {})

class JobOffer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      cvFile: null,

      isSending: false,
      isSent: false,
      showForm: false,
    }
    this.ref = React.createRef()
    this.spamCheck = React.createRef()
    this.zuriRef = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, this.ref.current.offsetTop)
  }

  openForm = () => {
    this.setState({ showForm: true })
  }

  setSelectedFiles = e => {
    const cvFile = e.target.files[0]
    this.setState({ cvFile })
  }

  zuriAnimation = () => {
    const { zuriRef } = this
    const element = zuriRef.current
    if (!element) return
    const elementTimeline = new TimelineMax()
    elementTimeline
      .to(element, 0, { display: 'block' })
      .to(element, 1.5, { top: '100vh', ease: Power0.easeNone })
      .to(element, 0, { display: 'none' })
  }

  onSendClick = async () => {
    const { name, email, message, cvFile } = this.state
    const { job } = this.props

    this.setState({ isSending: true })

    const errors = {}
    if (!email) errors.emailError = 'Email is required'
    if (email && !validateEmail(email)) errors.emailError = 'Email is not valid'
    if (!name) errors.nameError = 'Name is required'
    if (!message) errors.messageError = 'Message is required'

    this.setState(errors)
    if (Object.keys(errors).length !== 0) {
      const errorMessage = Object.keys(errors).reduce((result, key) => {
        result += `${errors[key]}\r\n`
        return result
      }, '')
      // alert(errorMessage)
      return this.setState({ isSending: false })
    }


    const data = new FormData()
    data.append('cv', cvFile)
    data.append('job', job._id)
    data.append('name', name)
    data.append('email', email)
    data.append('message', message)
    data.append('isSpam', !!this.spamCheck.current.value)

    try {
      // await axios.post('http://localhost:3000/api/v1/job-form', data, {
      await axios.post('/api/v1/job-form', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.setState({ isSent: true })
      this.zuriAnimation()

      // navigate(`${window.location.pathname}#contact-form`) // adjusts view to thansk message
    } catch (error) {
      alert('Sending failed')
    }
    this.setState({ isSending: false })
  }

  render() {
    const { job, onClose, texts } = this.props
    const { showForm, cvFile, isSending, isSent } = this.state

    const fields = createFields({
      fieldsDef,
      state: this.state,
      setState: value => this.setState(value),
    })

    return (
      <Wrapper ref={this.ref} id={job.slug} name="job offer wrapper">
        <Col
          name="job offer inner col"
          width="1200px"
          maxWidth="100%"
          alignSelf="center"
          position="relative"
        >
          <CloseImage src={closeIconSrc} onClick={onClose} />
          <Title style={{ paddingRight: '100px' }} mobileFontSize="30px">
            {job.title}
          </Title>
          <Gap gap="20px" />
          <Content dangerouslySetInnerHTML={{ __html: job.content }} />
          <Gap gap="40px" />
          <div style={{ position: 'relative' }}>
            <ZuriWrapper ref={this.zuriRef}>
              <img src={zuriCrossing} alt="" />
            </ZuriWrapper>
            {!isSent && !showForm && (
            <Button.Jobs onClick={this.openForm}>{texts.apply}</Button.Jobs>
            )}
          </div>
          {!isSent && showForm && (
            <div>
              <Row alignItems="center" maxWidth="100%">
                <TextField
                  maxWidth="418px"
                  style={{ width: '100%' }}
                  {...fields.name}
                />
                <Gap gap="20px" />
                <Label>{texts.name}</Label>
              </Row>
              <Gap gap="30px" />
              <Row alignItems="center" maxWidth="100%">
                <TextField
                  maxWidth="418px"
                  style={{ width: '100%' }}
                  {...fields.email}
                  type="email"
                />
                <Gap gap="20px" />
                <Label>EMAIL</Label>
              </Row>
              <SpamCheck
                ref={this.spamCheck}
                id="email-check"
                name="emailCheck"
                type="email"
              />
              <Gap gap="30px" />
              <TextAreaField {...fields.message} />
              <SelectFileField onSelect={this.setSelectedFiles}>
                <AddCvText>
                  {(cvFile && cvFile.name) || <>{texts.addCv}</>}
                </AddCvText>
              </SelectFileField>
              <Col alignItems="flex-end">
                <Button.Jobs disabled={isSending} onClick={this.onSendClick}>
                  {isSending ? <>{texts.submitting}</> : <>{texts.submit}</>}
                </Button.Jobs>
              </Col>
            </div>
          )}
          {isSent && <ThanksText>{texts.thanks}</ThanksText>}

          <Gap gap="150px" />
        </Col>
      </Wrapper>
    )
  }
}

export default JobOffer
