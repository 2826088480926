import { TweenLite, TimelineMax, Linear, Power1, Power0, Power2 } from 'gsap'
import { up, colors } from '../../lib/styles'

const TIME_COEF = 6

const HIDE_DURATION = 1

export const applyTimeCoef = (schedule, timeCoef = TIME_COEF) => schedule.map(el => ({ ...el, time: el.time * timeCoef }))

export const createTextAnimation = ({ schedule, isZuri }) => {
  const textTimeline = new TimelineMax()

  schedule.forEach(({ ref }, index) => {
    const delay = (index !== 0) ? schedule[index - 1].time : 0
    textTimeline.to(ref.current, 0, { color: isZuri ? colors.usageYellow : colors.usageBlue }, `=${delay}`)
  })

  return textTimeline
}

const ZURI_TAKEOFF_TIME = 1.5
export const createZuriAnimation = ({ elementRef, shadowRef, path, duration = 8, delay = 0, takeoffTime = ZURI_TAKEOFF_TIME }) => {
  const element = elementRef && elementRef.current
  const shadow = shadowRef && shadowRef.current
  if (!element || !shadow) return null

  const flyLength = duration - takeoffTime * 2

  const elementTimeline = new TimelineMax()
  elementTimeline
    .to(element, 0, { display: 'block', delay })
    .to(element, takeoffTime, { y: -50 })
    .to(element, flyLength, {
      bezier: path,
      ease: Power1.easeInOut,
    })
    .to(element, takeoffTime, { y: 0 })
  // .to(element, 0, { display: 'none' })

  const shadowTimeline = new TimelineMax()
  shadowTimeline
    .to(shadow, 0, { display: 'block', delay })
    .to(shadow, takeoffTime, { y: 10, scale: 1.1 })
    .to(shadow, flyLength, {
      bezier: path,
      ease: Power1.easeInOut,
    })
    .to(shadow, takeoffTime, { y: 0, scale: 1 })
  // .to(shadow, 0, { display: 'none' })

  const timeline = new TimelineMax()
  timeline.add(elementTimeline, '0').add(shadowTimeline, '0')
  return timeline
}

export const createPlaneAnimation = ({ elementRef, shadowRef, path, duration = 5, delay = 0 }) => {
  const element = elementRef && elementRef.current
  const shadow = shadowRef && shadowRef.current
  if (!element || !shadow) return null

  const planeTimeline = new TimelineMax()
  planeTimeline
    .to(element, 0, { display: 'block', delay })
    .to(element, 2, { ease: Power1.easeInOut, y: -50 }, '+=1')
    .to(
      element,
      duration,
      {
        bezier: path,
        ease: Power1.easeInOut,
        autoAlpha: 1,
      },
      '-=2.5',
    )
    .to(element, 2, { y: 0, ease: Power1.easeInOut }, '-=2')
    .to(element, 0, { display: 'none' })

  const shadowTimeline = new TimelineMax()
  shadowTimeline
    .to(shadow, 0, { display: 'block', delay })
    .to(shadow, 2, { ease: Power1.easeInOut, y: 10, scale: 1.1 }, '+=1')
    .to(
      shadow,
      duration,
      {
        bezier: path,
        ease: Power1.easeInOut,
      },
      '-=2.5',
    )
    .to(shadow, 2, { y: 0, scale: 1, ease: Power1.easeInOut }, '-=2')
    .to(shadow, 0, { display: 'none' })

  const timeline = new TimelineMax()
  timeline.add(planeTimeline, '0').add(shadowTimeline, '0')
  return timeline
}

const HELICOPTER_TAKEOFF_TIME = 0.3
export const createHelicopterAnimation = ({ elementRef, shadowRef, path, duration, isLast }) => {
  const element = elementRef && elementRef.current
  const shadow = shadowRef && shadowRef.current
  if (!element || !shadow) return null

  const flyTime = duration - HELICOPTER_TAKEOFF_TIME * 2

  const helicopterTimeline = new TimelineMax()
  helicopterTimeline
    .to(element, 0, { display: 'block' })
    .to(element, HELICOPTER_TAKEOFF_TIME, { y: -20 })
    .to(element, flyTime, {
      bezier: path,
      ease: Power1.easeInOut,
    })
    .to(element, HELICOPTER_TAKEOFF_TIME, { y: 0 })
  if (isLast) helicopterTimeline.to(element, HIDE_DURATION, { opacity: 0 })
  helicopterTimeline.to(element, 0, { display: 'none' })

  const shadowTimeline = new TimelineMax()
  shadowTimeline
    .to(shadow, 0, { display: 'block' })
    .to(shadow, HELICOPTER_TAKEOFF_TIME, { y: 5, scale: 1.1 })
    .to(shadow, flyTime, {
      bezier: path,
      ease: Power1.easeInOut,
    })
    .to(shadow, HELICOPTER_TAKEOFF_TIME, { y: 0, scale: 1 })
  if (isLast) shadowTimeline.to(shadow, HIDE_DURATION, { opacity: 0 })
  shadowTimeline.to(shadow, 0, { display: 'none' })


  const timeline = new TimelineMax()
  timeline.add(helicopterTimeline, '0').add(shadowTimeline, '0')
  return timeline
}

export const createPersonAnimation = ({ elementRef, path = [], duration }) => {
  const element = elementRef && elementRef.current
  if (!element) return null

  const elementTimeline = new TimelineMax()
  elementTimeline
    .to(element, 0, { display: 'block' })
    .to(element, duration, {
      bezier: path,
      ease: Power0.easeNone,
    })
    .to(element, 0, { display: 'none' })

  return elementTimeline
}

export const createCarAnimation = ({ elementRef, path = [], duration, isLast }) => {
  const element = elementRef && elementRef.current
  if (!element) return null

  const elementTimeline = new TimelineMax()
  elementTimeline
    .to(element, 0, { display: 'block' })
    .to(element, duration, {
      bezier: {
        curviness: 0.2,
        values: path,
      },
      ease: Power0.easeNone,
    })

  if (isLast) elementTimeline.to(element, HIDE_DURATION, { opacity: 0 })
  elementTimeline.to(element, 0, { display: 'none' })

  return elementTimeline
}

export const createBoatAnimation = ({ elementRef, path = [], duration }) => {
  const element = elementRef && elementRef.current
  if (!element) return null

  const elementTimeline = new TimelineMax()
  elementTimeline
    .to(element, 0, { display: 'block' })
    .to(element, duration, {
      bezier: {
        // type: 'soft',
        values: path,
        curviness: 0,
      },
      ease: Power1.easeInOut,
    })
    .to(element, 0, { display: 'none' })

  return elementTimeline
}

export const getBgImageStyle = animationConfig => animationConfig.reduce(
  (result, { bp, width, left }) => `
  ${result}
  ${up(bp)} { 
    width: ${width}px; 
    left: ${left}px;
   }`,
  '',
)

export const getAnimationStyle = ({ animationConfig, defaultImageWidth, animBase }) => animationConfig.reduce((result, { bp, width, left }) => {
  const rationCoef = width / defaultImageWidth
  const finalLeft = animBase.left * rationCoef + left
  const finalTop = animBase.top * rationCoef

  return `
  svg {
    position: absolute;
  }
  position: absolute;
  pointer-events: none;
  ${result}
  ${up(bp)} { 
    top: ${finalTop}px; 
    left: ${finalLeft}px;
    transform: scale(${rationCoef});
   }`
}, '')
