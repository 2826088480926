import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

const ImageOverlay = styled.div`
  ${({ width = '100%', position = 'absolute', isControllable, isVisible }) => css`
    position: ${position};
    width: ${width};
    left: 0;
    top: 0;
    height: 100%;
    background: black;
    opacity: 0;
    z-index: 10;
    transition: 0.3s;
    &:hover {
      ${!isControllable
        && css`
          opacity: 0;
        `}
    }

    ${isControllable
      && css`
        pointer-events: none;
      `}

    ${up('tablet')} {
      opacity: 0.4;

      ${isControllable
        && !isVisible
        && css`
          opacity: 0;
        `}
    }
  `}
`

export default ImageOverlay
