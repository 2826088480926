import styled, { css } from 'styled-components'

const Input = styled.input`
  ${({ theme: { colors, fonts }, hasError }) => css`
    height: 40px;
    background: white;
    outline: none;
    padding: 13px 32px;

    color: ${colors.textBlack};
    font-family: ${fonts.hind};
    line-height: normal;
    font-size: 16px;
    font-weight: 700;

    ${hasError
      && css`
        border: 2px solid ${colors.red};
      `}
    &:focus {
      /* border: 2px solid ${colors.green}; */
      /* padding: 8px 10px; */
    }
  `}
`

Input.Dark = styled.input`
  ${({ theme: { colors, fonts }, hasError }) => css`
  height: 30px;
  border: 1px solid white;
  background: black;

  ${hasError
    && css`
      border: 2px solid ${colors.red};
    `}

  outline: none;
  padding: 5px 15px;

  color: ${colors.textWhite};
  font-family: ${fonts.hind};
  line-height: normal;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    /* border: 2px solid ${colors.green}; */
    /* padding: 8px 10px; */
  }
`}
`

export default Input
