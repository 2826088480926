import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Input from './Input'
import Text from './Text'
import Gap from './Gap'
import Col from './Col'

const TextArea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  /* resize: none; */
  height: 200px;
  padding-top: 30px;
  padding-bottom: 30px;
`

const TextAreaField = ({ label, hasError, error, ...props }) => (
  <Col position="relative">
    <TextArea hasError={error || hasError} {...props} />
    {(error || hasError) && <Text.Error>{error || label}</Text.Error>}
  </Col>
)

TextAreaField.defaultProps = {
  label: '',
  hasError: false,
  error: '',
}

TextAreaField.propTypes = {
  label: PropTypes.string,
  hasError: PropTypes.bool,
  error: PropTypes.string,
}

export default TextAreaField
