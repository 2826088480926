import React from "react";
import styled, { css } from "styled-components";
import { up, addResponsivity, addPagePadding } from "../lib/styles";

const Col = styled.div`
  ${({
    theme: { colors },
    justifyContent,
    alignItems,
    alignSelf,
    mobileAlignSelf,
    mobileAlignItems,
    position,
    maxWidth,
    mobileMaxWidth,
    minWidth,
    maxHeight,
    width,
    mobileWidth,
    flex,
    grow,
    basis,
    mobileBasis,
    shrink,
    padding,
    margin,
    mobilePadding,
    mobileMargin,
    height,
    withPagePadding,
    background,
    textAlign,
    bp = "mobile",
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: ${mobileAlignItems || alignItems};
    justify-content: ${justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: ${minWidth};
    max-height: ${maxHeight};
    width: ${mobileWidth || width};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-basis: ${mobileBasis || basis};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};
    height: ${height};
    background: ${background};
    text-align: ${textAlign};

    ${up(bp)} {
      padding: ${padding};
      margin: ${margin};
      align-self: ${alignSelf};
      max-width: ${maxWidth};
      align-items: ${alignItems};
      width: ${width};
      flex-basis: ${basis};
    }
    ${withPagePadding && addPagePadding()}
    ${addResponsivity({ displayAs: "flex" })}
  `}
`;

export default Col;
