import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

const BgImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0%;
  z-index: 0;
`
const BgImage = ({ fluid, imgStyle, ...props }) => (
  <BgImageWrapper {...props}>
    <Img fluid={fluid} objectFit="cover" fit="cover" style={{ height: '100%' }} imgStyle={imgStyle} />
  </BgImageWrapper>
)

export default BgImage
