import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { TweenLite, TimelineMax, Linear, Power1 } from 'gsap'
import { Waypoint } from 'react-waypoint'

import {
  createZuriAnimation,
  createPlaneAnimation,
  createHelicopterAnimation,
  createPersonAnimation,
  createCarAnimation,
  createBoatAnimation,
  getBgImageStyle,
  getAnimationStyle,
  applyTimeCoef,
  createTextAnimation,
} from './helpers'
import {
  ZuriHopPath,
  CarHopPath,
  CarHop,
  Person,
  Boat,
  ZuriHop,
  ZuriHopShadow,
} from './images'
import CityDescription from './CityDescription'
import Content from './Content'
import Title from '../Title'
import Text from '../Text'
import Button from '../Button'

import { up } from '../../lib/styles'

import Col from '../Col'
import Gap from '../Gap'

const animBase = {
  top: 597,
  left: 437,
}

const defaultImageWidth = 1920

const animationConfig = [
  {
    bp: 0,
    width: 420,
    left: -80,
  },
  {
    bp: 340,
    width: 440,
    left: -80,
  },
  {
    bp: 360,
    width: 460,
    left: -80,
  },
  {
    bp: 380,
    width: 480,
    left: -80,
  },
  {
    bp: 400,
    width: 520,
    left: -100,
  },
  {
    bp: 420,
    width: 520,
    left: -80,
  },
  {
    bp: 440,
    width: 520,
    left: -60,
  },
  {
    bp: 460,
    width: 520,
    left: -40,
  },
  {
    bp: 480,
    width: 520,
    left: -20,
  },
  {
    bp: 500,
    width: 520,
    left: 0,
  },
  {
    bp: 520,
    width: 700,
    left: -145,
  },
  {
    bp: 555,
    width: 700,
    left: -110,
  },
  {
    bp: 590,
    width: 700,
    left: -75,
  },
  {
    bp: 625,
    width: 700,
    left: -40,
  },
  {
    bp: 660,
    width: 900,
    left: -190,
  },
  {
    bp: 680,
    width: 900,
    left: -170,
  },
  {
    bp: 720,
    width: 900,
    left: -130,
  },
  {
    bp: 760,
    width: 900,
    left: -85,
  },
  {
    bp: 805,
    width: 900,
    left: -40,
  },
  {
    bp: 860,
    width: 1200,
    left: -260,
  },
  {
    bp: 910,
    width: 1200,
    left: -210,
  },
  {
    bp: 990,
    width: 1200,
    left: -130,
  },
  {
    bp: 1070,
    width: 1200,
    left: -50,
  },
  {
    bp: 1140,
    width: 1500,
    left: -260,
  },
  {
    bp: 1220,
    width: 1500,
    left: -180,
  },
  {
    bp: 1300,
    width: 1500,
    left: -100,
  },
  {
    bp: 1380,
    width: 1920,
    left: -400,
  },
  {
    bp: 1520,
    width: 1920,
    left: -360,
  },
  {
    bp: 1560,
    width: 1920,
    left: -320,
  },
  {
    bp: 1600,
    width: 1920,
    left: -280,
  },
  {
    bp: 1640,
    width: 1920,
    left: -240,
  },
  {
    bp: 1680,
    width: 1920,
    left: -200,
  },
  {
    bp: 1720,
    width: 1920,
    left: -160,
  },
  {
    bp: 1760,
    width: 1920,
    left: -120,
  },
  {
    bp: 1800,
    width: 1920,
    left: -80,
  },
  {
    bp: 1840,
    width: 1920,
    left: -40,
  },
  {
    bp: 1880,
    width: 1920,
    left: 0,
  },
]

const BgWrapper = styled(Img)`
  height: auto;
  ${getBgImageStyle(animationConfig)}
`

const AnimationWrapper = styled.div`
  ${getAnimationStyle({
    animationConfig,
    defaultImageWidth,
    animBase,
  })}
`

const StyledContent = styled(Content)`
  top: -70px;
  ${up('tablet')} {
    top: -210px;
  }
  ${up(1140)} {
    top: -150px;
  }
  ${up(1380)} {
    top: -70px;
  }
`

const ContentBox = ({ zuriSchedule, carSchedule, ...props }) => (
  <Content.Box {...props}>
    <Content.ScheduleCol>
      <Title.UsageBox isZuri>ZURI 0:40 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {zuriSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} isZuri key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
    <Gap gap="0" mobileGap="5px" />
    <Col hideBelow="mobile" position="relative" style={{ left: '-40px' }}>
      <Title.UsageBox isZuri>/</Title.UsageBox>
    </Col>
    <Content.ScheduleCol>
      <Title.UsageBox>CAR AND BOAT 4:00 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {carSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
  </Content.Box>
)

const TIME_COEF = 9

class HopSection extends Component {
  constructor(props) {
    super(props)
    this.carRef = React.createRef()
    this.car2Ref = React.createRef()
    this.personRef = React.createRef()
    this.boatRef = React.createRef()
    this.zuriRef = React.createRef()
    this.zuriShadowRef = React.createRef()
    this.personZuriRef = React.createRef()

    this.carSchedule = [
      { text: ' 0:55  From the airport to the harbor', time: 11 / 12 },
      { text: '0:45  Wait for a ferry', time: 3 / 4 },
      { text: '2:00  Ferry to Phi Phi', time: 2 },
      { text: '0:20  Travel to the hotel', time: 1 / 3 },
    ]
    this.carSchedule = applyTimeCoef(this.carSchedule, TIME_COEF)
    this.carSchedule.forEach(el => {
      el.ref = React.createRef()
    })

    this.zuriSchedule = [
      { text: '0:10  Travel to vertiport', time: 1 / 6 },
      { text: '0:15  Zuri onboarding', time: 1 / 4 },
      { text: '0:15  Zuri flight to the hotel', time: (1 / 4) * 2 },
    ]
    this.zuriSchedule = applyTimeCoef(this.zuriSchedule, TIME_COEF)
    this.zuriSchedule.forEach(el => {
      el.ref = React.createRef()
    })

    this.zuriScheduleMobile = this.zuriSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))
    this.carScheduleMobile = this.carSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))

    this.state = {
      wasPlayed: false,
    }
  }

  zuriAnimation = () => {
    const personTimeline = createPersonAnimation({
      elementRef: this.personZuriRef,
      duration: this.zuriSchedule[0].time + this.zuriSchedule[1].time,
    })
    const zuriTimeline = createZuriAnimation({
      elementRef: this.zuriRef,
      shadowRef: this.zuriShadowRef,
      path: [
        { top: 84, left: 580 },
        { top: 264, left: 1124 },
      ],
      duration: this.zuriSchedule[2].time,
      takeoffTime: 0.2,
    })

    const timeline = new TimelineMax()
    timeline.add(personTimeline).add(zuriTimeline)

    const textTimeline = createTextAnimation({
      schedule: this.zuriSchedule,
      isZuri: true,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.zuriScheduleMobile,
      isZuri: true,
    })

    timeline.add(textTimeline, 0)
    timeline.add(mobileTextTimeline, 0)
    return timeline
  }

  carAnimation = () => {
    const carTimeline = createCarAnimation({
      elementRef: this.carRef,
      path: [
        { top: -25, left: 50 },
        { top: 15, left: 20 },
        { top: 70, left: 95 },
        { top: 100, left: 75 },
        { top: 115, left: 90 },
        { top: 135, left: 75 },
        { top: 155, left: 130 },
      ],
      duration: this.carSchedule[0].time,
    })
    const personTimeline = createPersonAnimation({
      elementRef: this.personRef,
      duration: this.carSchedule[1].time,
    })
    const boatTimeline = createBoatAnimation({
      elementRef: this.boatRef,
      path: [
        { top: 150, left: 200 },
        { top: 170, left: 300 },
        { top: 320, left: 1180 },
      ],
      duration: this.carSchedule[2].time,
    })
    const car2Timeline = createCarAnimation({
      elementRef: this.car2Ref,
      path: [
        { top: 335, left: 1205 },
        { top: 295, left: 1165 },
      ],
      duration: this.carSchedule[3].time,
      isLast: true,
    })
    const timeline = new TimelineMax()
    timeline
      .add(carTimeline)
      .add(personTimeline)
      .add(boatTimeline)
      .add(car2Timeline)

    const textTimeline = createTextAnimation({
      schedule: this.carSchedule,
      isZuri: false,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.carScheduleMobile,
      isZuri: false,
    })

    timeline.add(textTimeline, 0)
    timeline.add(mobileTextTimeline, 0)
    return timeline
  }

  startAnimation = () => {
    if (!this.wasOncePlayed) {
      this.wasOncePlayed = true
      this.createAnimation()
    }
  }

  createAnimation = () => {
    this.timeline = new TimelineMax({
      onStart: () => {
        this.setState({ wasPlayed: false })
      },
      onComplete: () => {
        this.setState({ wasPlayed: true })
      },
    })
    this.timeline.add(this.zuriAnimation(), 0).add(this.carAnimation(), 0)
  }

  restart = () => {
    if (this.timeline) {
      this.timeline.restart()
    }
  }

  render() {
    const { bgImage } = this.props
    const { wasPlayed } = this.state
    const zuriPosition = { top: '-30px', left: '-35px' }

    return (
      <Col position="relative">
        <Col position="relative">
          <BgWrapper fluid={bgImage.image.fluid} />
          <Button.Replay onClick={this.restart} show={wasPlayed} />
        </Col>
        {/* <Gap gap="280px" showBelow="tablet" /> */}
        {/* <Gap gap="50px" showBelow={380} /> */}
        <AnimationWrapper>
          <Waypoint bottomOffset="30%" onEnter={this.startAnimation} />

          <ZuriHopPath />
          <CarHopPath style={{ top: '-12px' }} />

          <CityDescription top={-80}>
            Phuket / International airport
          </CityDescription>
          <CityDescription top={230} left={1186} hideBelow={1520}>
            Phi Phi / Hotel
          </CityDescription>
          <CityDescription
            top={230}
            left={1186}
            showBelow={1520}
            textStyle={{ left: '-100px' }}
          >
            Phi Phi / Hotel
          </CityDescription>

          <ZuriHopShadow ref={this.zuriShadowRef} position={zuriPosition} />
          <ZuriHop ref={this.zuriRef} position={zuriPosition} />
          <Person
            ref={this.personZuriRef}
            position={{ top: '-25px', left: '-10px' }}
          />

          <CarHop
            ref={this.carRef}
            position={{ top: '-12px', left: '-12px' }}
          />
          <Person
            ref={this.personRef}
            position={{ top: '140px', left: '140px' }}
          />

          <Boat ref={this.boatRef} position={{ top: '140px', left: '140px' }} />

          <CarHop
            ref={this.car2Ref}
            position={{ top: '335px', left: '1205px' }}
          />
        </AnimationWrapper>
        <StyledContent>
          <Content.TextWrapper>
            <Col maxWidth="515px">
              <Title.Usage>HOP BETWEEN ISLANDS 65 KM</Title.Usage>
              <Gap gap="38px" mobileGap="20px" bp="tablet" />
              <Text.Usage>
                There are thousands of islands in Thailand, Maldives,
                Philippines or Indonesia. Most of them without an airport. The
                VTOL airplane can reach them, allowing you to skip boat travel.
              </Text.Usage>
            </Col>
          </Content.TextWrapper>
          <Gap gap="40px" />
          <ContentBox
            hideBelow="tablet"
            zuriSchedule={this.zuriSchedule}
            carSchedule={this.carSchedule}
          />
        </StyledContent>
        <ContentBox
          showBelow="tablet"
          zuriSchedule={this.zuriScheduleMobile}
          carSchedule={this.carScheduleMobile}
        />
      </Col>
    )
  }
}

export default HopSection
