import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import Section from './Section'
import Video from './Video'
import headerVideo from '../data/videos/header.mp4'
import { up, theme } from '../lib/styles'

const StyledCarousel = styled(Carousel)`
  height: 100%;
  width: 100%;
  .slider-list {
    width: 100%;
    height: ${theme.hpTopSectionMobileHeight};
    max-height: ${theme.hpTopSectionMaxHeight};
    ${up(theme.hpTopSectionBp)} {
      height: 100vh;
    }
  }
`
const NavigationDotWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  video {
    position: relative;
    top: 20px;
    /* width: 120%; */
    z-index: -1;
    ${up('mobile')} {
      top: 50px;
    }
    ${up('tablet')} {
      top: 50px;
    }

    ${up('desktop')} {
      top: 50px;
      width: 100%;
      top: 0;
    }
  }
`

const NavigationDot = styled.div`
  ${({ theme: { colors }, isActive }) => css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid white 1px;

    cursor: pointer;
    background: transparent;
    opacity: 0.8;

    ${isActive
    && css`
      background: white;
    `}

    ${up('mobile')} {
      width: 15px;
      height: 15px;
    }
  `}
`

const NavigationWrapper = styled.div`
  padding-right: 0;
  ${up('mobile')} {
    padding-right: 30px;
  }
`

const IMAGES_STYLE = {
  0: {
    objectPosition: '30% 10%',
  },
  1: {
    objectPosition: '70% 50%',
  },
  2: {
    objectPosition: '50% 50%',
  },
}

const HpTopCarousel = ({ images }) => (
  <>
    {/* renderCenterRightControls */}
    <StyledCarousel
      autoplay
      autoplayInterval={5000}
      // wrapAround
      renderCenterLeftControls={() => null}
      renderBottomCenterControls={() => null}
      renderCenterRightControls={({ pagingDotsIndices, currentSlide, goToSlide, ...rest }) => (
        <NavigationWrapper>
          {pagingDotsIndices.map((index) => (
            <NavigationDotWrapper onClick={() => goToSlide(index)}>
              <NavigationDot isActive={index === currentSlide} />
            </NavigationDotWrapper>
          ))}
        </NavigationWrapper>
      )}
    >
      {images.map((img, index) => (
        <Img
          fluid={img.image.fluid}
          //   objectFit="contain"
          style={{ height: '100%', width: '100%' }}
          cropFocus="attention"
          loading="eager"
          imgStyle={{
            objectFit: 'cover',
            ...IMAGES_STYLE[index],
          }}
        />
      ))}
      <VideoWrapper>
        <Video src={headerVideo} centered muted autoPlay loop />
      </VideoWrapper>
    </StyledCarousel>
  </>
)

export default HpTopCarousel
