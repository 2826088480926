import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const InstagramIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33">
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#fff"
              d="M16.066 19.421a2.82 2.82 0 0 0 2.816-2.825 2.82 2.82 0 0 0-2.816-2.825 2.82 2.82 0 0 0-2.816 2.825 2.82 2.82 0 0 0 2.816 2.825z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M20.606 13.07c-.576 0-1.024-.45-1.024-1.027 0-.578.448-1.028 1.024-1.028.576 0 1.024.45 1.024 1.028 0 .578-.448 1.027-1.024 1.027zm-4.544 7.899c-2.432 0-4.352-1.991-4.352-4.367a4.364 4.364 0 0 1 4.352-4.367c2.368 0 4.416 1.927 4.416 4.367s-1.984 4.367-4.416 4.367zm3.52-11.302h-7.04c-1.024 0-1.92.321-2.496.899-.576.578-.896 1.477-.896 2.504v7.064c0 1.027.32 1.926.96 2.568.64.578 1.472.9 2.496.9h6.976c1.024 0 1.92-.322 2.496-.9.64-.578.96-1.477.96-2.504v-7.064c0-1.027-.32-1.862-.896-2.504-.64-.642-1.472-.963-2.56-.963z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M25.578 21.209c0 1.477-.512 2.762-1.408 3.662-.896.9-2.176 1.349-3.584 1.349H11.61c-1.408 0-2.688-.45-3.584-1.35-.96-.899-1.408-2.184-1.408-3.661v-9.075c0-2.956 1.984-5.011 4.992-5.011h9.04c1.472 0 2.688.514 3.584 1.413.896.9 1.344 2.12 1.344 3.598zM16.194.677C7.49.677.45 7.741.45 16.474S7.49 32.27 16.194 32.27s15.744-7.063 15.744-15.796C32.002 7.74 24.898.677 16.194.677z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

InstagramIcon.defaultProps = {}

InstagramIcon.propTypes = {}

export default InstagramIcon
