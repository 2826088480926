import React from 'react'
import styled, { css } from 'styled-components'

const BgVideo = styled.div`
  ${({ centered }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    ${centered
      && css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
  `}
`

const StyledVideo = styled.video.attrs(() => ({
  playsinline: true,
}))`
  ${({ fullscreen }) => css`
    width: 100%;
    ${fullscreen
      && css`
        position: absolute;
        left: 0;
        top: 0;
        /* top: 50%; */
        /* left: 50%; */
        /* transform: translateX(-50%) translateY(-50%); */
        /* min-width: 100%; */
        /* min-height: 100%; */
        width: 100%;
        /* height: 100vh; */
        height: 100%;
        z-index: -1000;
        overflow: hidden;

        object-fit: cover;
      `}
  `}
`

const Video = ({ videoRef, src, type = 'video/mp4', ...props }) => (
  <StyledVideo
    {...props}
    ref={videoRef}
    playsInline
    onClick={() => {
      if (!videoRef || !videoRef.current) return
      const { current } = videoRef
      if (current.paused) {
        current.play()
      } else {
        current.pause()
      }
    }}
  >
    <source src={src} type={type} />
  </StyledVideo>
)

Video.Bg = ({ centered = false, fullscreen, ...props }) => (
  <BgVideo centered={centered}>
    <Video fullscreen={fullscreen} {...props} />
  </BgVideo>
)

export default Video
