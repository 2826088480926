import React from 'react'

const WIDTH = 584
const HEIGHT = 351

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Berlin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="584"
    height="351"
    viewBox="0 0 584 351"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="yu0ca">
        <path
          fill="#fff"
          d="M278.667.258c156.906 2.12 292.049 78.677 304.183 173.62 13.534 105.9-131.805 189.568-310.644 174.842C108.186 335.215-12.745 243.985 1.766 147.7 14.953 60.205 134.83-1.684 278.666.259z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M278.667.258c156.906 2.12 292.049 78.677 304.183 173.62 13.534 105.9-131.805 189.568-310.644 174.842C108.186 335.215-12.745 243.985 1.766 147.7 14.953 60.205 134.83-1.684 278.666.259z"
          clipPath='url("#yu0ca")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Berlin
