import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import Img from 'gatsby-image'

import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Text, { TextComponent } from './Text'
import Section from './Section'
import Title from './Title'
import Button from './Button'
import BgImage from './BgImage'
import Video from './Video'
import VH from './VH'
import Link from './Link'
import RH from './RH'
import VideoPopup from './VideoPopup'
import { MuteIcon, UnmuteIcon } from './icons'

import { up, addPagePadding } from '../lib/styles'

import logoLarge from '../data/images/hp/logo-large.png'
import lineImage from '../data/images/hp/yellow-line.svg'
import youtubeIcon from '../data/images/hp/youtube-icon-red.png'

import introVideo from '../data/videos/intro.mp4'
import islandHoppingVideo from '../data/videos/island-hopping.mp4'

import HpTopIcons from './HpTopIcon'

import t1 from '../data/videos/t1.mp4'


const HiddenTitle = styled.h1`
  display: none;
`

const HpTopText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

    ${up('mobile')} {
      font-size: 34px;
      line-height: 42px;
    }
  `}
`
const Content = styled.div`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 100vh;

  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  padding-top: 60px;
  padding-bottom: 40px;

  padding-left: 8px;
  padding-right: 8px;

  padding-bottom: 120px;
  
  ${up('mobile')} {
    ${addPagePadding()}
    padding-top: 120px;
    padding-bottom: 160px;
  }

  ${up('tablet')} {
    padding-top: 0;
    padding-bottom: 0;
    
    top: 50%;
    display: block;
    height: auto;

    height: 100vh;
    display: flex;
    padding-top: 120px;
    padding-bottom: 220px;
    padding-bottom: 160px;
    justify-content: space-around;
    justify-content: space-between;

  }
`

const LogoImage = styled.img`
  position: relative;
  /* max-width: 250px; */
  max-width: 200px;
  width: 100%;
  left: 0;
  ${up('mobile')} {
    max-width: 300px;
  }
  ${up('tablet')} {
    max-width: 345px;
    left: 34px;
  }
`

const ControlsWrapper = styled.div`
  position: absolute;
  /* bottom: 100px; */
  bottom: 0;
  left: 0;
  width: 100%;
  background: black;
  background-color: transparent;

  height: 150px;

  ${up('mobile')} {
    height: 100px;
  }


  /* display: none; */
  display: flex;
  ${up('tablet')} {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`

const LineWrapper = styled(Row)`
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 80px;
  ${up('mobile')} {
    top: 0;
  }
`

// const lineWidth = '2px'
const lineWidthActive = '2px'
// const lineColorDark = '#808080'
// const lineColorDarkActive = '#000000'

const Circle = styled.div`
  ${({ color = 'white', isActive }) => css`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px ${color};
    position: relative;
    cursor: pointer;

    ${isActive
      && css`
        border-width: ${lineWidthActive};
      `}
  `}
`

const CircleWrapper = styled.div`
  position: relative;
  top: -13px;
`

const Line = styled.div`
  ${({ color = 'white', number, progress, activeItem }) => css`
    background: ${color};
    height: 1px;
    width: 100%;
    position: relative;
    flex-shrink: 1;

    ${activeItem >= number
      && css`
        &::before {
          transition: 0.3s;
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: -1px;
          height: ${lineWidthActive};
          background: ${color};
          width: ${activeItem === number ? progress : '100'}%;
          z-index: 50;
        }
      `}
  `}
`

const CircleText = styled(HpTopText)`
  ${({ color = 'white' }) => css`
    position: absolute;
    top: 35px;
    /* width: 300px; */
    color: ${color};
    cursor: pointer;
    font-size: 15px;
    white-space: nowrap;
    transform: translateX(-30%);
    ${up('mobile')} {
      top: 50px;
      font-size: 19px;
      transform: none;
    }
  `}
`

const zoomAnimation = keyframes`
    from { 
      transform: scale(0.8);
      }
    to { 
      transform: scale(1.1);
      }
`

const zoomAnimationMobile = keyframes`
    from { 
      transform: scale(1);
      }
    to { 
      transform: scale(2);
      }
`

const PlaneBg = styled(BgImage)`
  top: 0;
  animation: ${zoomAnimationMobile} 20s both;
  animation-timing-function: ease-out;
  pointer-events: none;

  ${up('tablet')} {
    top: -100px;
    animation: ${zoomAnimation} 20s both;
  }
`

const RightControlsWrapper = styled.div`
  padding: 10px;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 20;
  ${up('tablet')} {
    top: 20px;
  }
`

const SoundIconWrapper = styled.div`
  cursor: pointer;
  width: 35px;
  display: none;

  ${up('mobile')} {
    display: block;
  }

  ${up('tablet')} {
    width: auto;
  }

  svg {
    max-width: 100%;
  }
`
const SoundIconMobileWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 4px;
  cursor: pointer;
  width: 35px;
  height: auto;
  ${up('mobile')} {
    display: none;
  }

  svg {
    max-width: 100%;
  }
`

const LineItems = styled(Row)`
  flex-shrink: 0.75;
  flex-basis: 100%;
  justify-content: space-evenly;

  ${up('mobile')} {
    flex-basis: 400px;
    justify-content: space-between;
    flex-shrink: 0;
  }
`

const countPercent = (part, from) => part / (from / 100)

const Item = ({ onClick, color, lineColor, children, number, activeItem }) => (
  <CircleWrapper onClick={onClick}>
    <Circle onClick={onClick} color={lineColor} isActive={number <= activeItem} />
    <CircleText onClick={onClick} color={color}>
      {children}
    </CircleText>
  </CircleWrapper>
)

class HpTop extends Component {
  constructor(props) {
    super(props)
    this.introVideoRef = React.createRef()
    this.islandHoppingVideoRef = React.createRef()
    this.videoPopupRef = React.createRef()
    this.topVideoRef = React.createRef()
    this.state = {
      isVideoPlaying: false,
      isMuted: false,
      activeItem: -1,
      progress: 0,
    }
  }

  startVideo = (selectedVideoRef) => {
    const { current: videoRef } = selectedVideoRef
    if (!videoRef) return
    const { activeItem } = this.state
    this.setState({ progress: 0 })

    this.introVideoRef.current.pause()
    this.islandHoppingVideoRef.current.pause()

    this.introVideoRef.current.currentTime = 0
    this.islandHoppingVideoRef.current.currentTime = 0

    this.setState({ isVideoPlaying: true })
    videoRef.play()
    const { duration } = videoRef
    videoRef.ontimeupdate = (data) => {
      const progress = countPercent(videoRef.currentTime, duration)
      this.setState({ progress })
    }
    videoRef.onended = ({ target }) => {
      if (target.webkitExitFullScreen) target.webkitExitFullScreen()
      if (this.state.activeItem === 0) {
        this.setActiveItem(1)
      } else {
        this.setState({ isVideoPlaying: false, progress: 0, activeItem: -1 })
      }
    }
  }

  toggleSound = () => {
    const { isMuted } = this.state
    this.setState({ isMuted: !isMuted })
  }

  setActiveItem = (index) => {
    switch (index) {
      case 0: {
        this.setState({ activeItem: index })
        this.startVideo(this.islandHoppingVideoRef)
        break
      }
      case 1: {
        this.setState({ activeItem: index })
        this.startVideo(this.introVideoRef)
        break
      }
      default:
        this.setState({ activeItem: index })
    }
  }

  openVideoPopup = () => {
    if (this.videoPopupRef.current) {
      this.introVideoRef.current.pause()
      this.islandHoppingVideoRef.current.pause()
      this.introVideoRef.current.currentTime = 0
      this.islandHoppingVideoRef.current.currentTime = 0

      this.setState({ isVideoPlaying: false, progress: 0, activeItem: -1 })
      this.videoPopupRef.current.open()
    }
  }

  render() {
    const { image } = this.props
    const { isVideoPlaying, isMuted, activeItem, progress } = this.state

    const lineColor = isVideoPlaying ? 'black' : 'white'
    const textColor = isVideoPlaying && activeItem === 1 ? 'black' : 'white'

    const SoundIcon = isMuted ? UnmuteIcon : MuteIcon

    const getLineProps = (number) => ({
      number,
      color: lineColor,
      activeItem,
      progress,
    })

    const getItemProps = (number) => ({
      number,
      activeItem,
      onClick: () => this.setActiveItem(number),
      color: textColor,
      lineColor,
    })

    return (
      <Section.FullHeight>
        <VideoPopup ref={this.videoPopupRef} />
        <Gap.Top />
        <VH show={!isVideoPlaying}>
          {/* <PlaneBg fluid={image.image.fluid} imgStyle={{ objectFit: 'contain' }} critical /> */}
          <Video.Bg
            src={t1}
            centered
            muted
            fullscreen
            style={{ top: '-50px' }}
            autoPlay
            loop
          />
        </VH>

        <VH show={isVideoPlaying && activeItem === 0}>
          <Video.Bg
            videoRef={this.islandHoppingVideoRef}
            src={islandHoppingVideo}
            centered
            muted={isMuted}
            fullscreen
            style={{ top: '54px' }}
          />
        </VH>
        <VH show={isVideoPlaying && activeItem === 1}>
          <Video.Bg
            videoRef={this.introVideoRef}
            src={introVideo}
            centered
            muted={isMuted}
            fullscreen
            style={{ top: '54px' }}
          />
        </VH>

        <VH show={!isVideoPlaying}>
          <Content>
            <HiddenTitle>Zuri</HiddenTitle>

            <Col justifyContent="center" alignItems="center" maxWidth="100%">
              {/* <Gap gap="92px" mobileGap="0" bp="tablet" /> */}
              <Gap gap="0px" mobileGap="0" bp="tablet" />
              <LogoImage src={logoLarge} alt="Zuri logo" />
              <Gap gap="40px" mobileGap="20px" bp="tablet" />
              <img src={lineImage} alt="" style={{ maxWidth: '100%' }} />
              <Gap gap="24px" mobileGap="12px" bp="tablet" />
              <HpTopText>Advanced Regional Air Mobility</HpTopText>
              {/* <Gap gap="80px" mobileGap="0" bp="tablet" /> */}
            </Col>


            <Col
              justifyContent="center"
              alignItems="center"
              maxWidth="100%"
              style={{ pointerEvents: 'none' }}
            >
              <Button.Watch onClick={() => this.setActiveItem(0)} />
              {/* <Gap gap="0" mobileGap="0" bp="tablet" /> */}
            </Col>

            <Col>
              {/* <Gap gap="32px" mobileGap="0" /> */}
              <HpTopIcons />
              {/* <Gap gap="160px" mobileGap="60px" /> */}
            </Col>
          </Content>
        </VH>

        <ControlsWrapper>
          <RightControlsWrapper>
            <Row alignItems="center">
              {activeItem === 0 && (
                <div onClick={this.openVideoPopup}>
                  <Row alignItems="center" style={{ cursor: 'pointer' }}>
                    <TextComponent fontSize="14px" mobileFontSize="14px" white fontWeight="700">
                      watch full video
                    </TextComponent>
                    <Gap gap="10px" />
                    <img src={youtubeIcon} alt="youtube icon" />
                  </Row>
                </div>
              )}

              <Gap gap="20px" />
              <SoundIconWrapper onClick={this.toggleSound}>
                <SoundIcon color={textColor} />
              </SoundIconWrapper>
            </Row>
          </RightControlsWrapper>

          {/* <Row showAfter="tablet" style={{ width: '100%' }} margin="0 auto"> */}
          <LineWrapper>
            <Line {...getLineProps(-1)} />
            <LineItems
              // width="780px"
              basis="400px"
              justifyContent="space-between"
              shrink="0"
            >
              <Item {...getItemProps(0)}>ISLAND HOPPING</Item>
              <Line {...getLineProps(0)} />
              <Item {...getItemProps(1)}>TAKE OFF</Item>
            </LineItems>
            <Line {...getLineProps(1)} />
            <SoundIconMobileWrapper onClick={this.toggleSound}>
              <SoundIcon color={textColor} />
            </SoundIconMobileWrapper>
          </LineWrapper>
          {/* <Gap gap="0" mobileGap="50px" /> */}
        </ControlsWrapper>
      </Section.FullHeight>
    )
  }
}

export default HpTop
