import React from 'react'

const SpeedIcon = () => (
  <svg
    width="600"
    height="600"
    viewBox="0 0 600 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M259.32 154.08C240.63 156.829 217.129 163.013 217.816 164.8C218.091 165.625 222.351 178.268 227.161 192.836C235.132 216.887 236.232 219.223 238.706 218.536C240.217 218.123 248.601 216.062 257.259 214.275C270.04 211.389 277.736 210.702 297.114 210.29L321.164 209.878L325.287 181.842C328.173 162.876 329.135 153.668 328.036 152.981C325.562 151.194 272.376 152.019 259.32 154.08Z"
      fill="black"
    />
    <path
      d="M335.045 181.704C332.846 196.684 330.785 209.603 330.51 210.427C330.235 211.252 333.945 212.626 339.992 213.588C352.636 215.787 376.961 223.621 390.292 229.668C395.927 232.279 400.737 234.203 400.874 234.065C401.287 233.653 409.258 221.009 424.787 196.134L431.934 184.728L416.404 177.306C396.889 167.961 381.634 162.739 361.432 158.203C336.694 152.706 339.58 150.095 335.045 181.704Z"
      fill="black"
    />
    <path
      d="M190.605 173.733C167.516 183.903 141.679 199.57 126.012 212.901L120.515 217.574L128.073 225.545C132.196 230.08 141.267 239.7 148.138 246.984L160.645 260.315L171.502 252.206C184.283 242.724 203.111 232.004 216.717 226.644C222.076 224.445 226.612 222.659 226.886 222.384C227.986 221.697 208.333 166.999 207.096 167.137C206.409 167.137 198.988 170.16 190.605 173.733Z"
      fill="black"
    />
    <path
      d="M424.101 214.688L408.846 239.15L415.168 243.136C425.337 249.458 437.294 258.941 447.189 268.561L456.534 277.356L480.86 262.514C494.328 254.405 505.872 247.259 506.559 246.572C508.209 245.06 483.196 220.185 470.69 210.565C457.771 200.67 442.516 190.362 440.867 190.362C440.043 190.362 432.484 201.357 424.101 214.688Z"
      fill="black"
    />
    <path
      d="M100.725 236.539C87.6686 250.282 75.5746 265.812 66.3667 280.792C57.0214 295.772 50.6996 308.691 52.0739 309.928C53.4482 311.165 102.923 330.542 104.71 330.542C105.397 330.542 107.734 327.244 109.658 323.259C116.117 310.615 129.86 291.787 141.954 279.006L153.91 266.637L134.395 245.747C123.813 234.34 114.33 224.858 113.506 224.858C112.544 224.72 106.909 230.08 100.725 236.539Z"
      fill="black"
    />
    <path
      d="M515.904 263.476C514.393 265.262 504.635 273.783 494.465 282.441C484.295 291.1 475.912 298.658 475.912 299.208C475.774 299.758 478.523 304.43 481.821 309.515C500.375 338.376 512.744 375.207 515.217 408.741L516.042 421.247H545.59H575L574.038 408.466C571.152 369.16 560.157 329.58 542.841 297.559C537.619 287.664 520.302 260.452 519.34 260.452C519.065 260.452 517.554 261.827 515.904 263.476Z"
      fill="black"
    />
    <path
      d="M404.997 319.41L310.17 376.994L302.886 375.62C280.485 371.084 258.633 388.951 258.633 411.764C258.633 419.873 262.344 429.905 267.841 436.227C281.309 452.031 306.459 453.131 321.714 438.701C327.624 433.066 332.846 422.209 332.983 415.062V409.565L417.504 336.727C463.818 296.597 501.886 263.476 501.886 262.789C501.886 262.239 501.474 261.827 500.924 261.827C500.237 261.827 457.084 287.801 404.997 319.41Z"
      fill="black"
    />
    <path
      d="M45.7521 320.785C42.5912 326.694 33.2459 357.341 30.6347 370.397C27.7486 384.965 25 406.679 25 416.162V421.247L54.2729 420.972L83.4083 420.56L84.3703 408.741C85.6072 391.15 89.1804 374.383 95.3648 356.242C98.3883 347.171 100.587 339.75 100.312 339.613C100.037 339.338 87.9435 334.528 73.3758 328.893C49.7376 319.548 46.8516 318.723 45.7521 320.785Z"
      fill="black"
    />
  </svg>
)

export default SpeedIcon
