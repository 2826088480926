import React from 'react'

const WIDTH = 619
const HEIGHT = 437

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Paris = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="619"
    height="437"
    viewBox="0 0 619 437"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="ovaaa">
        <path
          fill="#fff"
          d="M193.252 38.513C343.216-26.265 522.154-8.738 591.77 89.093c80.473 113.092-28.937 274.856-225.488 330.183C192.677 468.144 34.738 411.142 5.29 302.272c-26.03-96.23 54.066-205.92 187.962-263.759z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M193.252 38.513C343.216-26.265 522.154-8.738 591.77 89.093c80.473 113.092-28.937 274.856-225.488 330.183C192.677 468.144 34.738 411.142 5.29 302.272c-26.03-96.23 54.066-205.92 187.962-263.759z"
          clipPath='url("#ovaaa")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Paris
