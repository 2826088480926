import React from 'react'
import styled, { css } from 'styled-components'
import topVideoSrc from '../data/videos/showreel.mp4'
import Video from './Video'
import Section from './Section'
import { up } from '../lib/styles'

// const Wrapper = styled(Section.FullHeight)`
const Wrapper = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* background: white; */
  /* height: auto; */
  /* overflow: hidden; */

  video {
    width: 100%;
    /* ${up('mobile')} {
      width: 100%;
    } */
  }
`

const HpShowReel = () => (
  <Wrapper>
    <Video src={topVideoSrc} centered muted autoPlay loop />
  </Wrapper>
)

export default HpShowReel
