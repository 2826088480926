import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { TweenLite, TimelineMax, Linear, Power1 } from 'gsap'
import { Waypoint } from 'react-waypoint'
import {
  createZuriAnimation,
  createPlaneAnimation,
  createHelicopterAnimation,
  createPersonAnimation,
  getBgImageStyle,
  getAnimationStyle,
  applyTimeCoef,
  createTextAnimation,
} from './helpers'

import {
  ZuriBusinessPath,
  ZuriShadow,
  Zuri,
  PlaneBusinessPath,
  Plane,
  PlaneShadow,
  Helicopter,
  HelicopterShadow,
  Person,
} from './images'
import Gap from '../Gap'
import Col from '../Col'
import Title from '../Title'
import Text from '../Text'
import Button from '../Button'
import CityDescription from './CityDescription'
import Content from './Content'
import { up, colors } from '../../lib/styles'

const animBase = {
  top: 471,
  left: 800,
}

const defaultImageWidth = 1920

const animationConfig = [
  {
    bp: 0,
    width: 980,
    left: -380,
  },
  {
    bp: 480,
    width: 980,
    left: -280,
  },
  {
    bp: 700,
    width: 980,
    left: -130,
  },
  {
    bp: 850,
    width: 980,
    left: 0,
  },
  {
    bp: 980,
    width: 1420,
    left: 0,
  },
  {
    bp: 1120,
    width: 1500,
    left: 0,
  },
  {
    bp: 1190,
    width: 1600,
    left: 0,
  },
  {
    bp: 1320,
    width: 1720,
    left: 0,
  },
  {
    bp: 1510,
    width: 1800,
    left: 0,
  },
  {
    bp: 1612,
    width: 1920,
    left: 0,
  },
]

const AnimationWrapper = styled.div`
  ${getAnimationStyle({
    animationConfig,
    defaultImageWidth,
    animBase,
  })}
`

const BgWrapper = styled(Img)`
  height: auto;
  ${getBgImageStyle(animationConfig)}
`

const TIME_COEF = 6

const ContentBox = ({ zuriSchedule, planeSchedule, ...props }) => (
  <Content.Box {...props}>
    <Content.ScheduleCol>
      <Title.UsageBox isZuri>ZURI 1:50 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {zuriSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} isZuri key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
    <Gap gap="0" mobileGap="5px" />
    <Col hideBelow="mobile" position="relative" style={{ left: '-40px' }}>
      <Title.UsageBox isZuri>/</Title.UsageBox>
    </Col>
    <Content.ScheduleCol>
      <Title.UsageBox>AIRLINER 2:50 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {planeSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
  </Content.Box>
)

class BusinessTripSection extends Component {
  constructor(props) {
    super(props)
    this.zuriShadowRef = React.createRef()
    this.zuriRef = React.createRef()
    this.planeShadowRef = React.createRef()
    this.planeRef = React.createRef()
    this.helicopterShadowRef = React.createRef()
    this.helicopterRef = React.createRef()
    this.personTransferRef = React.createRef()
    this.personStartRef = React.createRef()

    this.zuriSchedule = [
      { text: '0:10 Travel to vertiport', time: 1 / 6 },
      { text: '0:15 Zuri onboarding', time: 1 / 4 },
      { text: '1:20 Zuri flight', time: 4 / 3 },
      { text: '0:05 Offboarding and baggage', time: 1 / 12 },
    ]
    this.zuriSchedule = applyTimeCoef(this.zuriSchedule, TIME_COEF)

    this.planeSchedule = [
      { text: '0:20 Taxi to airport', time: 1 / 3 },
      { text: '1:00 Airport: checks and waiting', time: 1 },
      { text: '1:00 Flight', time: 1 },
      { text: '0:15 Offboarding and boarding', time: 1 / 4 },
      { text: '0:15 Helicopter transfer', time: 1 / 4 },
    ]
    this.planeSchedule = applyTimeCoef(this.planeSchedule, TIME_COEF)

    this.zuriSchedule.forEach(el => {
      el.ref = React.createRef()
    })
    this.planeSchedule.forEach(el => {
      el.ref = React.createRef()
    })

    this.zuriScheduleMobile = this.zuriSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))
    this.planeScheduleMobile = this.planeSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))

    this.state = {
      wasPlayed: false,
    }
  }

  startAnimation = () => {
    if (!this.wasOncePlayed) {
      this.wasOncePlayed = true
      this.createAnimation()
    }
  }

  createAnimation = () => {
    this.timeline = new TimelineMax({
      onStart: () => {
        this.setState({ wasPlayed: false })
      },
      onComplete: () => {
        this.setState({ wasPlayed: true })
      },
    })
    this.timeline.add(this.zuriAnimation(), 0).add(this.planeAnimation(), 0)
  }

  restart = () => {
    if (this.timeline) {
      this.timeline.restart()
    }
  }

  zuriAnimation = () => {
    const zuriTimeline = createZuriAnimation({
      elementRef: this.zuriRef,
      shadowRef: this.zuriShadowRef,
      path: [
        { top: 160, left: 150 },
        { top: 430, left: 344 },
      ],
      duration: this.zuriSchedule[2].time,
      delay: this.zuriSchedule[0].time + this.zuriSchedule[1].time,
    })

    const textTimeline = createTextAnimation({
      schedule: this.zuriSchedule,
      isZuri: true,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.zuriScheduleMobile,
      isZuri: true,
    })

    zuriTimeline.add(textTimeline, 0)
    zuriTimeline.add(mobileTextTimeline, 0)
    return zuriTimeline
  }

  planeAnimation = () => {
    const { planeSchedule } = this

    const person1Timeline = createPersonAnimation({
      elementRef: this.personStartRef,
      duration: planeSchedule[0].time + planeSchedule[1].time,
    })

    const planeTimeline = createPlaneAnimation({
      elementRef: this.planeRef,
      shadowRef: this.planeShadowRef,
      path: [
        { top: 166, left: 50 },
        { top: 465, left: 215 },
      ],
      duration: planeSchedule[2].time,
    })

    const person2Timeline = createPersonAnimation({
      elementRef: this.personTransferRef,
      duration: planeSchedule[3].time,
    })

    const helicopterTimeline = createHelicopterAnimation({
      elementRef: this.helicopterRef,
      shadowRef: this.helicopterShadowRef,
      path: [
        { top: 480, left: 240 },
        { top: 449, left: 331 },
      ],
      duration: planeSchedule[4].time,
      isLast: true,
    })

    const timeline = new TimelineMax()
    timeline
      .add(person1Timeline)
      .add(planeTimeline)
      .add(person2Timeline)
      .add(helicopterTimeline)

    const textTimeline = createTextAnimation({
      schedule: this.planeSchedule,
      isZuri: false,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.planeScheduleMobile,
      isZuri: false,
    })

    timeline.add(textTimeline, 0)
    timeline.add(mobileTextTimeline, 0)

    return timeline
  }

  render() {
    const { bgImage, windowWidth } = this.props
    const { wasPlayed } = this.state

    return (
      <Col position="relative">
        <Col position="relative">
          <BgWrapper fluid={bgImage.image.fluid} critical />
          <Button.Replay onClick={this.restart} show={wasPlayed} />
        </Col>

        {/* <Gap gap="220px" showBelow="tablet" /> */}
        <AnimationWrapper>
          <Waypoint bottomOffset="50%" onEnter={this.startAnimation} />
          <ZuriBusinessPath style={{ position: 'relative' }} />
          <PlaneBusinessPath style={{ position: 'relative' }} />

          <CityDescription top={-80}>Geneva / Business centre</CityDescription>

          <CityDescription top={400} left={400} hideBelow={1460}>
            Monaco / Port Hercules
          </CityDescription>
          <CityDescription
            top={362}
            left={400}
            showBelow={1460}
            textStyle={{ left: '-50px' }}
          >
            Monaco
            <br />
            Port Hercules
          </CityDescription>

          <ZuriShadow ref={this.zuriShadowRef} />
          <Zuri ref={this.zuriRef} />

          <PlaneShadow ref={this.planeShadowRef} />
          <Plane ref={this.planeRef} />

          <HelicopterShadow ref={this.helicopterShadowRef} />
          <Helicopter ref={this.helicopterRef} />

          <Person
            ref={this.personTransferRef}
            position={{ top: '475px', left: '300px' }}
          />
          <Person
            ref={this.personStartRef}
            position={{ top: '-20px', left: '-10px' }}
          />
        </AnimationWrapper>
        <Content>
          <Content.TextWrapper>
            <Col maxWidth="515px">
              <Title.Usage>BUSINESS TRIP 300 KM</Title.Usage>
              <Gap gap="38px" mobileGap="20px" bp="tablet" />
              <Text.Usage>
                Travel between major cities and other destinations up to 700 km
                apart to reach your business needs faster and on demand.
              </Text.Usage>
            </Col>
          </Content.TextWrapper>
          <Gap gap="125px" />
          <ContentBox
            hideBelow="tablet"
            zuriSchedule={this.zuriSchedule}
            planeSchedule={this.planeSchedule}
          />
        </Content>
        <ContentBox
          showBelow="tablet"
          zuriSchedule={this.zuriScheduleMobile}
          planeSchedule={this.planeScheduleMobile}
        />
      </Col>
    )
  }
}

export default BusinessTripSection
