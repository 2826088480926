import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import YouTube from './YouTube'
import { up } from '../lib/styles'
import closeIconSrc from '../data/images/career/close-icon.svg'

const PageOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.9;
  z-index: 1000;
`
const Popup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
`

const CloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  padding: 32px;
  cursor: pointer;
  z-index: 1500;
  width: 95px;
`

const ContentWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150vh;
  max-width: 100%;

`

class VideoPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

    open = () => this.setState({ isOpen: true })

    close = () => this.setState({ isOpen: false })

    render() {
      const { isOpen } = this.state
      if (!isOpen) return null
      return (
        <>
          <PageOverlay onClick={this.close} />
          <Popup onClick={this.close}>
            <CloseIcon src={closeIconSrc} onClick={this.close} />
            <ContentWrapper>
              <YouTube videoId="glQPEky3VlQ" autoplay={1} />
            </ContentWrapper>
          </Popup>
        </>
      )
    }
}

export default VideoPopup
