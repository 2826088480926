// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hybrid-js": () => import("./../../../src/pages/hybrid.js" /* webpackChunkName: "component---src-pages-hybrid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("./../../../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-pages-vr-js": () => import("./../../../src/pages/vr.js" /* webpackChunkName: "component---src-pages-vr-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/CareerTemplate.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

