import React from 'react'

const WIDTH = 622
const HEIGHT = 378

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Brusel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="622"
    height="378"
    viewBox="0 0 622 378"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="bokja">
        <path
          fill="#fff"
          d="M211.354 29.018c154.785-52.563 332.36-31.927 391.403 56.964 67.498 101.619-57.842 238.693-256.631 280.004C171.726 402.23 22.364 348.402 2.594 253.74c-17.507-83.827 69.771-177.524 208.76-224.722z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M211.354 29.018c154.785-52.563 332.36-31.927 391.403 56.964 67.498 101.619-57.842 238.693-256.631 280.004C171.726 402.23 22.364 348.402 2.594 253.74c-17.507-83.827 69.771-177.524 208.76-224.722z"
          clipPath='url("#bokja")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Brusel
