import React from 'react'

const WIDTH = 500
const HEIGHT = 219

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Stockholm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="219"
    viewBox="0 0 500 219"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="w8s1a">
        <path
          fill="#fff"
          d="M210.78.135c129.77 1.84 260.198 49.185 284.866 110.77 28.2 70.405-98.426 121.734-259.117 104.732C95.552 200.721-5.839 140.517.695 83.625 6.48 33.246 94.255-1.517 210.78.135z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M210.78.135c129.77 1.84 260.198 49.185 284.866 110.77 28.2 70.405-98.426 121.734-259.117 104.732C95.552 200.721-5.839 140.517.695 83.625 6.48 33.246 94.255-1.517 210.78.135z"
          clipPath='url("#w8s1a")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Stockholm
