import React from 'react'

const WIDTH = 735
const HEIGHT = 579

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Monaco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="735"
    height="579"
    viewBox="0 0 735 579"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="c2f2a">
        <path
          fill="#fff"
          d="M234.442 39.196C416.878-38.366 628.526 1.41 706.637 137.677c88.778 154.874-39.519 359.903-267.872 423.018C236.08 616.715 44.579 535.949 6.799 387.153-26.699 255.217 69.383 109.37 234.442 39.196z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M234.442 39.196C416.878-38.366 628.526 1.41 706.637 137.677c88.778 154.874-39.519 359.903-267.872 423.018C236.08 616.715 44.579 535.949 6.799 387.153-26.699 255.217 69.383 109.37 234.442 39.196z"
          clipPath='url("#c2f2a")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Monaco
