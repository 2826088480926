import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'

import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Text, { TextComponent } from './Text'
import { up, addPagePadding } from '../lib/styles'

import RangeIcon from './icons/hp/range'
import HybridIcon from './icons/hp/hybrid'
import SpeedIcon from './icons/hp/speed'
import LandingIcon from './icons/hp/landingArea'
import WeightIcon from './icons/hp/weight'
import NoiseIcon from './icons/hp/noise'

const IconsSection = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center; */

  display: grid;
  /* grid-template-columns: repeat(3, minmax(max-content, max-content)); */
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */

  max-width: 1000px;
  margin: auto;
  z-index: 100000;
  background: transparent;
  position: relative;
  svg,
  g,
  path {
    fill: #f0f0f0;
  }

  grid-gap: 8px 4px;

  ${up('mobile')} {
    grid-template-columns: repeat(5, 1fr);
  }

  ${up('tablet')} {
    grid-gap: 8px 24px;
    grid-template-columns: repeat(5, 1fr);
  }
`

const IconWrapper = styled.div`
  height: 50px;
  width: 50px;
  ${up('mobile')} {
    height: 70px;
    width: 70px;
  }
  ${up('tablet')} {
    height: 74px;
    width: 74px;
  }
`

const IconItem = styled.div`
  /* padding: 0 2px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 200px;


  grid-column: span 2;
  margin: 0 auto;
  &:nth-child(4) {
    grid-column: 2 / span 2;
  } 
  &:nth-child(5) {
    grid-column: 4 / span 2;
  }

  ${up('mobile')} {
    grid-column: span 1;
    margin: 0 auto;
    &:nth-child(4) {
      grid-column: auto;
    } 
    &:nth-child(5) {
      grid-column: auto;
    }
  }

  svg {
    max-width: 100%;
    height: auto;
  }
  /* ${up('tablet')} {
    padding: 0 12px;
  } */
`
const IconDescription = styled(Text)`
  font-weight: 400;
  color: #f0f0f0;
  font-size: 15px;
  line-height: 20px;
  margin-top: 8px;
  text-align: center;

  ${up('mobile')} {
    font-size: 18px;
    line-height: 24px;
  }
  ${up('tablet')} {
    font-size: 18px;
    line-height: 24px;
  }
`

const HpTopIcons = () => (
  <IconsSection>
    <IconItem>
      <IconWrapper>
        <RangeIcon />
      </IconWrapper>

      <IconDescription>700+ km range</IconDescription>
    </IconItem>
    <IconItem>
      <IconWrapper>
        <HybridIcon />
      </IconWrapper>
      <IconDescription>Hybrid powertrain extending range</IconDescription>
    </IconItem>
    <IconItem>
      <IconWrapper>
        <SpeedIcon />
      </IconWrapper>

      <IconDescription>
        300-350 km/h
        <br />
        cruise speed
      </IconDescription>
    </IconItem>
    <IconItem>
      <IconWrapper>
        <LandingIcon />
      </IconWrapper>
      <IconDescription>26x26 m landing area</IconDescription>
    </IconItem>
    {/* <IconItem>
      <IconWrapper>
        <WeightIcon />
      </IconWrapper>
      <IconDescription>2 000 kg MTOW</IconDescription>
    </IconItem> */}
    <IconItem>
      <IconWrapper>
        <NoiseIcon />
      </IconWrapper>
      <IconDescription>Very low acoustic signature</IconDescription>
    </IconItem>
  </IconsSection>
)

export default HpTopIcons
