// has to be filename of ./pages/*
// the actual route paths are defined in translations

export default {
  START: '/',
  TECHNOLOGY: '/technology',
  HYBRID: '/hybrid',
  INVEST: '/invest',
  STORY: '/story',
  USES: '/uses',
  VR: '/vr',
  CAREER: '/career',
  CAREER_CS: '/kariera',
  NEWS: '/news',
  CONTACT: '/contact',
}
