import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { addPagePadding, up, theme } from '../lib/styles'
import Video from './Video'
import ImageOverlay from './ImageOverlay'

const showVideoBp = 980

const Content = styled.section`
  ${({ theme: { sizes }, width }) => css`
    position: relative;
    max-width: ${width || sizes.contentWidth};
    display: block;
    margin: auto;
    z-index: 20;
  `}
`

const Bg = styled.div`
  ${({ theme: { fullHeight }, background, withoutPagePadding = false }) => css`
    position: relative;
    background: ${background || 'white'};
    ${fullHeight
      && css`
        height: 100vh;
      `}

    ${!withoutPagePadding && addPagePadding()}
  `}
`

const BgImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0%;
  z-index: 10;
`

const StyledVideo = styled(Video)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

class Section extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
    }
  }

  componentDidMount() {
    const { video } = this.props
    if (video) {
      this.setShowVideo()
      window.addEventListener('resize', this.setShowVideo)
    }
  }

  componentWillUnmount() {
    const { video } = this.props
    if (video) {
      window.removeEventListener('resize', this.setShowVideo)
    }
  }

  setShowVideo = () => {
    const windowWidth = window && window.innerWidth
    const showVideo = windowWidth > showVideoBp
    this.setState({ showVideo })
  }

  render() {
    const {
      image,
      video,
      fullHeight,
      background,
      withoutPagePadding,
      isBackgroundOverlaid,
      ...props
    } = this.props
    const { showVideo } = this.state
    const showBackground = !(video && showVideo)
    return (
      <Bg fullHeight={fullHeight} withoutPagePadding={withoutPagePadding} background={background}>
        {video && <StyledVideo src={video} autoPlay muted loop />}
        {typeof isBackgroundOverlaid !== 'undefined' && <ImageOverlay isControllable isVisible={isBackgroundOverlaid} />}

        <Content {...props} />
        {showBackground && image && (
          <BgImageWrapper>
            <Img
              fluid={image.image.fluid}
              objectFit="cover"
              fit="cover"
              style={{ height: '100%' }}
            />
          </BgImageWrapper>
        )}
      </Bg>
    )
  }
}

Section.FullHeight = styled.section`
  position: relative;
  height: 100vh;
  background: black;
`

// used on homepage with carousel
Section.FullHeightTop = styled.section`
  position: relative;
  height: ${theme.hpTopSectionMobileHeight};
  max-height: ${theme.hpTopSectionMaxHeight};
  background: black;
  margin-top: 60px;

  ${up('menu')} {
    margin-top: 0;
  }

  ${up(theme.hpTopSectionBp)} {
    margin-top: 0;
    height: 100vh;
  }

`

Section.Simple = styled.section`
  ${({ transparent, background }) => css`
    position: relative;
    background: ${transparent ? 'transparent' : 'white'};
    ${background
      && css`
        background: ${background};
      `}
  `}
`

export default Section
