import React from 'react'

const WIDTH = 688
const HEIGHT = 650

const getScaledDimension = ({ scale }) => ({
  width: WIDTH * scale,
  height: HEIGHT * scale,
})

const getColorProps = ({ white }) => ({ stroke: white ? '#ffffff' : '#ffda10' })

const Madrid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="688"
    height="650"
    viewBox="0 0 688 650"
    {...getScaledDimension(props)}
    {...props}
  >
    <defs>
      <clipPath id="8zw7a">
        <path
          fill="#fff"
          d="M175.621 72.096C336.29-37.614 545.52-21.802 641.344 118.548c107.73 157.785 18.111 394.575-185.32 491.863-180.726 86.43-373.459 25.723-435.343-128.338-55.58-138.371 9.585-310.723 154.94-409.977z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="none"
          stroke="#ffda10"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M175.621 72.096C336.29-37.614 545.52-21.802 641.344 118.548c107.73 157.785 18.111 394.575-185.32 491.863-180.726 86.43-373.459 25.723-435.343-128.338-55.58-138.371 9.585-310.723 154.94-409.977z"
          clipPath='url("#8zw7a")'
          {...getColorProps(props)}
        />
      </g>
    </g>
  </svg>
)

export default Madrid
