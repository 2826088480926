import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactYouTube from 'react-youtube'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import VH from './VH'
import Button from './Button'
import { up } from '../lib/styles'

const VideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: auto;
  z-index: 1;
`

const Player = styled(ReactYouTube)``

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;

  & > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
  /* padding-bottom: 40px; */
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`

const Thumbnail = styled(Img)`
  width: 100%;
  height: 100%;
`

const OverlayButton = styled.img`
  position: absolute;
  left: 50%;
  top: 49%;
  /* width: 27%; */
  width: 16%;
  z-index: 600;
  transform: translate(-50%, -50%);
  pointer-events: none;
  cursor: pointer;
  ${up(450)} {
    /* width: 20%; */
  }
  ${up('mobile')} {
    /* width: 16%; */
  }
`

const WatchButton = styled(Button.Watch)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(105,105,105,0.6);
`

class YouTube extends Component {
  video = null

  constructor(props) {
    super(props)
    this.state = {
      showThumbnail: true,
    }
  }

  saveRef = e => {
    this.video = e.target
  }

  startVideo = () => {
    if (!this.video) return
    this.video.playVideo()
    this.setState({ showThumbnail: false })
  }

  pauseVideo = () => {
    if (!this.video) return

    this.setState({ showThumbnail: false })
    this.video.pauseVideo()
  }

  stateChange = ({ data }) => {
    // video ended
    if (data === 0) {
      this.setState({ showThumbnail: true })
    }
  }

  render() {
    const { thumbnail, videoId, autoplay = 0, ...rest } = this.props
    const { showThumbnail } = this.state
    return (
      <VideoWrapper>
        <PlayerWrapper>
          <Player
            videoId={videoId}
            opts={{
              width: 560,
              height: 315,
              playerVars: {
                modestbranding: 1,
                // controls: 0,
                rel: 0,
                autoplay,
              },
            }}
            {...rest}
            onReady={this.saveRef}
            onStateChange={this.stateChange}
            style={{ width: '100%' }}
          />
        </PlayerWrapper>
        {thumbnail && showThumbnail && (
          <Overlay onClick={this.startVideo}>
            <Thumbnail fluid={thumbnail} alt="" />
            <WatchButton onClick={this.startVideo} />
          </Overlay>
        )}
      </VideoWrapper>
    )
  }
}

YouTube.propTypes = {
  videoId: PropTypes.string.isRequired,
}

export default YouTube
