import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'

const Fill = styled.div`
  ${({ height = '38px', mobileHeight, bp = 'mobile', color = 'black' }) => css`
    height: ${mobileHeight || height};
    width: 100%;
    background: ${color};
    ${addResponsivity()}
    z-index: 10;

    ${up(bp)} {
      height: ${height};
    }
  `}
`

Fill.Top = () => <Fill height="149px" showAfter="desktop" />

export default Fill
