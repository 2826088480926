import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../../lib/styles'
import Row from '../Row'
import Col from '../Col'

const ContentWrapper = styled.div`
  ${({ top }) => css`
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;

    ${up('tablet')} {
      padding-left: 100px;
      top: ${top};
    }
  `}
`

const TextWrapper = styled.div`
  ${({ theme: { mobilePageSidePadding } }) => css`
    padding: 26px ${mobilePageSidePadding};
    max-width: 100%;
    width: 100%;
    pointer-events: auto;

    ${up('tablet')} {
      padding: 26px 43px;
    }
  `}
`

const BoxWrapper = styled.div`
  ${({ theme: { mobilePageSidePadding } }) => css`
    background: rgb(112, 129, 172);
    padding: 26px ${mobilePageSidePadding};
    pointer-events: auto;
    /* max-width: 612px; */
    width: 100%;
    position: relative;
    bottom: 0;

    display: flex;
    justify-content: center;
    ${up('tablet')} {
      position: absolute;
      background: rgba(155, 162, 176, 0.47);
      position: relative;
      width: 612px;
      padding: 26px 43px;
    }

    ${addResponsivity()}
  `}
`

ContentWrapper.Box = ({ maxWidth = '612px', children, ...props }) => (
  <BoxWrapper {...props}>
    <Row justifyContent="space-between" maxWidth={maxWidth} width="100%" wrap="wrap">
      {children}
    </Row>
  </BoxWrapper>
)

ContentWrapper.TextWrapper = TextWrapper

ContentWrapper.ScheduleCol = styled(Col)`
  flex-basis: 135px;
  flex-grow: 1;
  ${up('tablet')} {
    flex-basis: 200px;
  }
`

export default ContentWrapper
