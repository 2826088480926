import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Title from './Title'
import Col from './Col'
import Gap from './Gap'
import Section from './Section'
import RH from './RH'
import { up, down, addPagePadding, addResponsivity } from '../lib/styles'

import line1Src from '../data/images/technology/top-line1.png'
import line2Src from '../data/images/technology/top-line2.png'
import line3Src from '../data/images/technology/top-line3.png'
import line4Src from '../data/images/technology/top-line4.png'

import line1MobileSrc from '../data/images/technology/top-line-mobile1.png'
import line2MobileSrc from '../data/images/technology/top-line-mobile2.png'
import line3MobileSrc from '../data/images/technology/top-line-mobile3.png'
import line4MobileSrc from '../data/images/technology/top-line-mobile4.png'

const textWrapperCommon = css`
  position: absolute;
  display: flex;
  flex-direction: row;

  ${down('tablet')} {
    font-size: 18px;
  }
  ${down('mobile')} {
    font-size: 14px;
  }
`
// rotors
const Text1Wrapper = styled.div`
  ${textWrapperCommon}
  text-align: right;

  /* display: block; */ // todo - toto případně řeší problém na mobilu, jen je potřeba lehce přepozicovat

  top: initial;
  height: auto;
  bottom: 75%;
  right: 60%;

  ${up('mobile')} {
    bottom: 75%;
    right: 50%;
  }
  ${up('tablet')} {
    /* bottom: 81%;
    right: 55%; */
    bottom: 70%;
    right: 56%;
  }
`
// seats
const Text2Wrapper = styled.div`
  ${textWrapperCommon}
  height: auto;
  top: auto;
  height: auto;
  right: 67%;
  bottom: 53%;
  ${up('mobile')} {
    right: 64%;
    bottom: 50%;
  }
  ${up('tablet')} {
    right: 62%;
    bottom: 50%;
  }
`

// wing
const Text3Wrapper = styled.div`
  ${textWrapperCommon}
  align-items: flex-end;
  top: 25%;
  right: 12%;

  /* ${up(450)} {
  right: 20%;

  } */

  ${up('mobile')} {
    top: 64%;
    left: 20%;
    right: unset;
  }
  ${up('tablet')} {
    top: 24%;
    left: 67%;
  }
`
const Text4Wrapper = styled.div`
  ${textWrapperCommon}
  align-items: flex-end;
  top: 45%;
  right: 39%;
  ${up('mobile')} {
    /* left: 56.5%; */
    left: 60%;
    right: unset;
    top: 54%;
  }
  ${up('tablet')} {
    /* left: 56.5%; */
    left: 56.5%;
    right: unset;
    top: 54%;
  }
`
const textCommon = css`
  /* position: relative;
  ${down('mobile')} {
    font-size: 18px;
  } */
  position: relative;
  font-size: 18px;
  ${up('mobile')} {
    font-size: 24px;
  }
`

// rotors
const Text1 = styled(Title.Small)`
  ${textCommon}
  padding-left: 10px;
  top: -30px;
  top: 0;
  left: 150px;
  left: 230px;
  width: 200px;
  text-align: left;
  ${up('mobile')} {
    text-align: right;
    top: 10px;
    left: 100px;
  }

  ${up(750)}{
    text-align: right;
    top: 10px;
    left: 116px;
  }

  ${up('tablet')} {
    width: auto;
    top: -10px;
    left: 0;
  }
`
const Text2 = styled(Title.Small)`
  ${textCommon}
  /* top: -30px; */
  top: 0;
  /* left: 115px; */
  left: 28px;
  width: 100px;
  ${up('mobile')} {
    top: 10px;
    left: 115px;
  }
  ${up('tablet')} {
    text-align: right;
    width: auto;
    top: -10px;
    left: 0;
  }
`
const Text3 = styled(Title.Small)`
  ${textCommon}
  /* max-width: 150px; */

  top: 0;
  left: 70px;
  text-align: right;

  ${up('mobile')} {
    text-align: left;
    top: -8px;
    left: -115px;
    max-width: 210px;
  }

  ${up('tablet')} {
    width: auto;
    top: -8px;
    left: -115px;
  }

  ${up(1200)} {
    top: 10px;
    left: 0;
  }
`
const Text4 = styled(Title.Small)`
  ${textCommon}
  padding-right: 10px;
  max-width: 275px;
  top: 100px;
  top: 0;
  left: -115px;
  left: -0;
  text-align: right;

  ${up('mobile')} {
    text-align: left;

    top: 115px;
    left: -115px;
  }

  ${up('tablet')} {
    width: auto;
    top: -8px;
    left: -115px;
  }

  ${up(1200)} {
    top: 10px;
    left: 0;
  }
`

const LineImage = styled.img`
  width: auto;
  height: 150px;
  ${up('mobile')} {
    width: 200px;
    height: auto;
  }
  ${up('tablet')} {
    height: auto;
    width: auto;
  }
  ${addResponsivity()}
`

const LineImage3 = styled(LineImage)`
  height: 275px;
`

const Wrapper = styled.div`
  margin-bottom: 80px;
  ${up('mobile')} {
    margin-bottom: 40px;
  }
  ${up('tablet')} {
    margin-bottom: 0;
  }
`

const TechnologyTop = ({ image, imageTablet }) => (
  <Wrapper>
    <Gap gap="80px" showBelow={1520} />
    <Gap gap="80px" showBelow="mobile" />

    <Col position="relative" height="100%">
      <Col showBelow="tablet" height="100%">
        <Img
          fluid={imageTablet.image.fluid}
          imgStyle={{ objectFit: 'contain' }}
          style={{ height: '100%' }}
          critical
        />
      </Col>

      {/* <Col hideBelow="mobile" showBelow="tablet" height="100%">
        <Img
          fluid={imageTablet.image.fluid}
          imgStyle={{ objectFit: 'contain' }}
          style={{ height: '100%' }}
        />
      </Col> */}

      <Col hideBelow="tablet" height="100%">
        <Img
          fluid={image.image.fluid}
          imgStyle={{ objectFit: 'contain' }}
          style={{ height: '100%' }}
        />
      </Col>

      <Text1Wrapper>
        <Text1>ROTORS OF THE VTOL SYSTEM</Text1>
        <Gap gap="20px" />
        <LineImage src={line1Src} alt="" showAfter="mobile" />
        <LineImage src={line1MobileSrc} alt="" hideAfter="mobile" />
      </Text1Wrapper>
      <Text2Wrapper>
        <Text2>4&nbsp;-&nbsp;5 SEATS</Text2>
        <Gap gap="20px" />
        <LineImage src={line2Src} alt="" showAfter="mobile" />
        <LineImage src={line1MobileSrc} alt="" hideAfter="mobile" />
      </Text2Wrapper>
      <Text3Wrapper>
        <LineImage src={line3Src} alt="" showAfter="mobile" />
        <Gap gap="20px" />
        <Text3>WINGS PROVIDE LONG RANGE</Text3>
        <LineImage3 src={line3MobileSrc} alt="" hideAfter="mobile" />
      </Text3Wrapper>
      {/* <Text4Wrapper>
        <LineImage src={line4Src} alt="" showAfter="mobile" />
        <Gap gap="20px" />
        <Text4>PROPULSION SYSTEM FOR CRUISE</Text4>
        <LineImage src={line4MobileSrc} alt="" hideAfter="mobile" />
      </Text4Wrapper> */}
    </Col>
    <Gap gap="120px" showBelow="mobile" />
  </Wrapper>
)

export default TechnologyTop
