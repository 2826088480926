import React from 'react'

const NoiseIcon = () => (
  <svg
    width="600"
    height="600"
    viewBox="0 0 600 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M342.651 71.8816C333.34 74.5288 329.962 77.5411 278.023 128.841L226.997 179.32H180.535C129.965 179.32 126.77 179.594 116.729 184.523C108.97 188.265 101.85 195.659 97.56 204.331L94 211.634V299.811V387.989L97.4687 395.109C103.858 407.98 116.181 417.199 130.056 419.39C133.434 419.938 155.341 420.303 181.448 420.303H226.997L278.023 470.69C315.723 507.933 330.51 521.899 334.253 524.09C350.957 533.492 373.778 527.741 383.545 511.767C389.752 501.726 389.295 519.617 389.295 299.811C389.295 105.473 389.295 101 387.561 95.9799C383.91 85.5738 375.877 77.2672 365.836 73.4334C359.355 70.9688 348.401 70.2386 342.651 71.8816ZM347.306 299.811C347.306 397.665 347.032 477.81 346.576 477.81C346.211 477.81 323.299 455.264 295.732 427.605L245.527 377.401L190.758 377.218L135.989 376.944V299.811V222.679L190.758 222.405L245.527 222.222L295.732 172.017C323.299 144.359 346.211 121.813 346.576 121.813C347.032 121.813 347.306 201.866 347.306 299.811Z"
      fill="black"
    />
    <path
      d="M454.562 193.834C449.906 195.02 446.255 197.394 442.786 201.319C438.587 206.248 437.401 210.082 437.857 217.202C438.131 222.313 438.679 223.683 443.334 230.529C457.3 251.432 463.233 271.058 463.233 296.251C463.233 321.171 456.296 343.718 442.512 363.982C436.031 373.384 434.936 376.123 434.936 382.147C435.027 398.304 452.462 407.98 466.611 399.673C476.195 394.105 491.987 366.355 499.198 342.348C503.58 327.835 505.314 316.881 505.862 300.085C506.409 282.742 505.405 272.062 501.48 256.179C496.916 237.466 490.526 222.952 480.12 207.343C474.278 198.58 471.631 196.207 465.333 194.29C460.769 192.921 458.76 192.829 454.562 193.834Z"
      fill="black"
    />
  </svg>
)

export default NoiseIcon
